import React, { useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import SlideA from "../images/slides/slide-a.jpg";

import startpositivoimg from "../images/produtos/start-positivo.jpg";
import escudopositivoimg from "../images/produtos/escudo-positivo.jpg";
import guardiaopositivoimg from "../images/produtos/guardiao-positivo.jpg";
import guiapositivoimg from "../images/produtos/guia-positivo.jpg";
import jornadapositivoimg from "../images/produtos/jornada-positivo.jpg";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardLink,
  Button,
  Progress
} from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, BlockDes, BackTo } from "../components/block/Block";
import { PreviewCard, CodeBlock } from "../components/preview/Preview";
import { BlockBetween } from "../components/Component"

import { fetchUserProductsDetails } from "./MeusProdutosData";
import { useNavigate } from "react-router-dom";

const MeusProdutos = ({ ...props }) => {
  
    const navigate = useNavigate();

    // Pega itens do Json
    const [data, setData] = useState([]);

    const navigate_product = (v, category) => {
        if(category){
            if(category == "course"){
                navigate(`/demo9/acesso-positivo/${v}`);
            }
        }else{
            navigate(`/demo9/acesso-positivo/${v}`);
        }

    };

    useEffect(() => {
      const fetchData = async () => {
        const data3 = await fetchUserProductsDetails();
        setData(data3);
      };
  
      fetchData();
    }, []);

    const cardTitleStyle = {
        minHeight: '20px',
    };
    
    const cardTextStyle = {
        minHeight: '80px',
    };
    
    const cardInnerStyle = {
        //display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    };
    
    const cardFullHeightStyle = {
        height: '100%',
    };

    const colStyle = {
        marginBottom: '30px',
    };

    return (
    <React.Fragment>
      <Head title="Invest Dashboard" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Meus Produtos</BlockTitle>
              <BlockDes className="text-soft">
                <p>Acesse aqui seus produtos POSITIVO já adquiridos</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        
        <Block size="lg">
        <PreviewCard>
            <Row>
            {data.length > 0 ? (
                data.map((item) => (
                <Col lg="4" style={colStyle} key={item.id_produto}>
                    <Card style={cardFullHeightStyle}>
                    <CardImg top src={item.produto_img} alt="" />
                    <CardBody style={cardInnerStyle}>
                        <CardTitle tag="h5" style={cardTitleStyle}>{item.produto_titulo}</CardTitle>
                        <CardText style={cardTextStyle}>
                        {item.produto_descricao}
                        </CardText>
                        <Button color="primary" className="mt-auto" onClick={() => navigate_product(item.id_produto, item.category)}>Acessar Produto</Button>
                    </CardBody>
                    <CardFooter className="border-top">
                        Último Acesso: {item.visto_ultimo}
                        <Progress animated value={item.ultimo_progresso} />
                        {item.ultimo_progresso}%
                    </CardFooter>
                    </Card>
                </Col>
                ))
            ) : (
                <div className="ms-2">Nenhum produto encontrado</div>
            )}
            </Row>
        </PreviewCard>
        </Block>
        
      </Content>
    </React.Fragment>
  );
};

export default MeusProdutos;
