export const newUserData = [
  {
    initial: "BA",
    theme: "purple-dim",
    name: "Beatriz Amaral",
    email: "bia.amaral@gmail.com",
    data_cadastro: "11/06/2015",
  },
  {
    initial: "SW",
    theme: "danger-dim",
    name: "Fabiola Duarte",
    email: "fabizinha@hotmail.com",
    data_cadastro: "10/06/2015",
  },
  {
    initial: "GO",
    theme: "warning-dim",
    name: "MC Lovera",
    email: "mc.lovera.vidaloka@yahoo.com.br",
    data_cadastro: "10/06/2015",
  },
  {
    initial: "PS",
    theme: "success-dim",
    name: "M4NOBH",
    email: "m4nobh@gmail.com",
    data_cadastro: "09/06/2015",
  },
];
