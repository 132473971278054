import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  
  ModalBody,
  Modal,
  DropdownItem,
  Form,
  Badge,
  Card,
} from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewAltCard,
  Icon,
  TooltipComponent,
  Row,
  Col,
  OverlineTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  RSelect,
} from "../components/Component";
import { cryptoActivityOptions, filterCoin, filterPaymentmethod, filterStatusOptions, orderData } from "../pages/pre-built/trans-list/TransData";
import { useForm } from "react-hook-form";
import { requests } from "../services/api";
import OrderOverview from "../components/partials/crypto/order-overview/OrderOverview";
import ActionCenter from "../components/partials/crypto/action-center/ActionCenter";
import CoinOrder from "../components/partials/crypto/coin-order/CoinOrder";
import UserActivity from "../components/partials/crypto/user-activity/UserActivity";
import OrderActivity from "../components/partials/order-activity/OrderActivity";

const AnalyticsHomePage = () => {
  const [sm, updateSm] = useState(false);

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    add: false,
  });
  const [modalDetail, setModalDetail] = useState(false);
  const [data, setData] = useState(orderData);
  const [detail, setDetail] = useState({});
  const [formData, setFormData] = useState({
    orderType: "Deposit",
    amountBTC: "",
    amountUSD: "",
    balanceBTC: "",
    balanceUSD: "",
    status: "Pending",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [wallet_info, set_wallet_info] = useState([]);


  useEffect(() => {
    fetchWalletInfo();
  }, []);

  const fetchWalletInfo = async () => {
    try {
      const response = await requests("https://solutions.bradosmart.com.br/app/api/wallet/info", "GET", {});
      const data = await response.json();
      console.log(data);
      set_wallet_info(data.message); // Certifique-se de que data.message contém os dados esperados
    } catch (error) {
      console.error("Erro ao buscar dados de indicações:", error);
    }
};

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.ref.localeCompare(b.ref));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.ref.localeCompare(a.ref));
      setData([...sortedData]);
    }
  };


  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = orderData.filter((item) => {
        return item.ref.toLowerCase().includes(onSearchText.toLowerCase());
      });
      setData([...filteredObject]);
    } else {
      setData([...orderData]);
    }
  }, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      orderType: "Buy",
      amountBTC: "",
      amountUSD: "",
      balanceBTC: "",
      balanceUSD: "",
      status: "Pending",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ add: false });
    resetForm();
  };

  // submit function to add a new item
  const onFormSubmit = (submitData) => {
    const { amountBTC, amountUSD, balanceUSD, balanceBTC } = submitData;
    let submittedData = {
      id: data.length + 1,
      ref: "YWLX52JG73",
      date: "18/10/2019 12:04 PM",
      desc: formData.orderType === "Profit" ? "Credited " + formData.orderType : formData.orderType + " Funds",
      orderType: formData.orderType,
      amountBTC: amountBTC,
      amountUSD: amountUSD,
      balanceBTC: balanceBTC,
      balanceUSD: balanceUSD,
      status: formData.status,
    };
    setData([submittedData, ...data]);
    resetForm();
    setModal({ add: false });
  };

  useEffect(() => {
    reset(formData)
  }, [formData]);

  // function to change to approve property for an item
  const onApproveClick = (id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].status = "Completed";
    setData([...newData]);
  };

  // function to change to reject property for an item
  const onRejectClick = (id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].status = "Rejected";
    setData([...newData]);
  };

  // function to load detail data
  const loadDetail = (id) => {
    let index = data.findIndex((item) => item.id === id);
    setDetail(data[index]);
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // function to toggle details modal
  const toggleModalDetail = () => setModalDetail(!modalDetail);

  const { reset, register, handleSubmit, formState: { errors } } = useForm();

  return (
    <React.Fragment>
      <Head title="Crypto Dashboard" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Meu Saldo</BlockTitle>
              <BlockDes className="text-soft">
                <p>Acompanhe a sua carteira de comissões e bônus</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col lg="8">
              <PreviewAltCard className="h-100">
                <OrderOverview info={wallet_info} />
              </PreviewAltCard>
            </Col>
            <Col lg="4">
              <Card className=" h-100">
                <ActionCenter />
              </Card>
            </Col>
            <Col lg="12">
              <Card className=" card-full">
                
                <Block>
                  <DataTable className="card-stretch">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Histórico de Saques</h5>
                        </div>
                        <div className="card-tools me-n1">
                          <ul className="btn-toolbar gx-1">
                            <li>
                              <Button
                                href="#search"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  toggle();
                                }}
                                className="btn-icon search-toggle toggle-search"
                              >
                                <Icon name="search"></Icon>
                              </Button>
                            </li>
                            <li className="btn-toolbar-sep"></li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end className="filter-wg dropdown-menu-xl">
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">Advanced Filter</span>
                                    <div className="dropdown">
                                      <Button size="sm" className="btn-icon">
                                        <Icon name="more-h"></Icon>
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-4">
                                      <Col size="6">
                                        <div className="form-group">
                                          <label className="overline-title overline-title-alt">Type</label>
                                          <RSelect options={cryptoActivityOptions} placeholder="Any Activity" />
                                        </div>
                                      </Col>
                                      <Col size="6">
                                        <div className="form-group">
                                          <label className="overline-title overline-title-alt">Status</label>
                                          <RSelect options={filterStatusOptions} placeholder="Any Status" />
                                        </div>
                                      </Col>
                                      <Col size="6">
                                        <div className="form-group">
                                          <label className="overline-title overline-title-alt">Pay Currency</label>
                                          <RSelect options={filterCoin} placeholder="Any coin" />
                                        </div>
                                      </Col>
                                      <Col size="6">
                                        <div className="form-group">
                                          <label className="overline-title overline-title-alt">Method</label>
                                          <RSelect options={filterPaymentmethod} placeholder="Any Method" />
                                        </div>
                                      </Col>

                                      <Col size="6">
                                        <div className="form-group">
                                          <div className="custom-control custom-control-sm custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="includeDel" />
                                            <label className="custom-control-label" htmlFor="includeDel">
                                              {" "}
                                              Including Deleted
                                            </label>
                                          </div>
                                        </div>
                                      </Col>

                                      <Col size="12">
                                        <div className="form-group">
                                          <Button type="button" className="btn btn-secondary">
                                            Filter
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="dropdown-foot between">
                                    <a
                                      href="#reset"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                      className="clickable"
                                    >
                                      Reset Filter
                                    </a>
                                    <a
                                      href="#save"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      Save Filter
                                    </a>
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Order</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                        <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                          <div className="search-content">
                            <Button
                              onClick={() => {
                                setSearchText("");
                                toggle();
                              }}
                              className="search-back btn-icon toggle-search"
                            >
                              <Icon name="arrow-left"></Icon>
                            </Button>
                            <input
                              type="text"
                              className="border-transparent form-focus-none form-control"
                              placeholder="Search by Order Id"
                              value={onSearchText}
                              onChange={(e) => onFilterChange(e)}
                            />
                            <Button className="search-submit btn-icon">
                              <Icon name="search"></Icon>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <DataTableBody bodyclass="nk-tb-tnx">
                      <DataTableHead>
                        <DataTableRow>
                          <span>Details</span>
                        </DataTableRow>
                        <DataTableRow size="xxl">
                          <span>Source</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>Order</span>
                        </DataTableRow>
                        <DataTableRow className="text-end">
                          <span>Amount</span>
                        </DataTableRow>
                        <DataTableRow size="sm" className="text-end">
                          <span>Balance</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-status">
                          <span className="sub-text d-none d-md-block">Status</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools"></DataTableRow>
                      </DataTableHead>

                      {Array.isArray(wallet_info?.history) ? wallet_info.history.length > 0
                        ? wallet_info.history.map((item) => {
                            return (
                              <DataTableItem key={item.id}>
                                <DataTableRow>
                                  <div className="nk-tnx-type">
                                    <div
                                      className={`nk-tnx-type-icon bg-${
                                        item.color
                                      }`}
                                    >
                                      <Icon name="arrow-up-right"></Icon>
                                    </div>
                                    <div className="nk-tnx-type-text">
                                      <span className="tb-lead">{item.operation}</span>
                                      <span className="tb-date">{item.datetime_created}</span>
                                    </div>
                                  </div>
                                </DataTableRow>
                                <DataTableRow size="xxl">
                                  <span className="tb-lead-sub">Using PayPal Account</span>
                                  <span className="tb-sub">mypay*****com</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                  <span className="tb-lead-sub"></span>
                                  <Badge
                                    className="badge-dot"
                                    color="success"
                                  >
                                    {item.orderType}
                                  </Badge>
                                </DataTableRow>
                                <DataTableRow className="text-end">
                                  <span className="tb-amount">
                                    + {item.amountBTC} <span>BTC</span>
                                  </span>
                                  <span className="tb-amount-sm">{item.value}</span>
                                </DataTableRow>
                                <DataTableRow className="text-end" size="sm">
                                  <span className="tb-amount">
                                    {item.balanceBTC} <span>BTC</span>
                                  </span>
                                  <span className="tb-amount-sm">0</span>
                                </DataTableRow>
                                <DataTableRow className="nk-tb-col-status">
                                  <div
                                    className={`dot dot-${item.color} d-md-none`}
                                  ></div>
                                  <Badge
                                    className="badge-sm badge-dim d-none d-md-inline-flex"
                                    color={`outline-${
                                        item.color
                                    }`}
                                  >
                                    {item.status}
                                  </Badge>
                                </DataTableRow>
                              </DataTableItem>
                            );
                          })
                        : null: null}
                    </DataTableBody>
                    <div className="card-inner">
                      {currentItems.length > 0 ? (
                        <PaginationComponent
                          noDown
                          itemPerPage={itemPerPage}
                          totalItems={data.length}
                          paginate={paginate}
                          currentPage={currentPage}
                        />
                      ) : (
                        <div className="text-center">
                          <span className="text-silent">No data found</span>
                        </div>
                      )}
                    </div>
                  </DataTable>
                </Block>
              </Card>
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AnalyticsHomePage;