import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Content from "../layout/content/Content";
import { Card, Badge } from "reactstrap";
import Head from "../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
} from "../components/Component";
import { getProdutoById, getAssuntosById, getConteudoById, checkUserProduct } from "./AcessoPositivoData";
import ReactPlayer from 'react-player';
import { truncate } from "../utils/Utils";

const AcessoPositivo = () => {
    const [sm, updateSm] = useState(false);
    const [mobileView , setMobileView] = useState(false);
    const [produto, setProduto] = useState([]);
    const [assuntos, setAssuntos] = useState([]);
    const [conteudo, setConteudo] = useState([]);
    const { id_produto } = useParams();

    const eclick = async (id_conteudo, id_produto) => {
        var has_access = await checkUserProduct(id_produto);
        if(has_access){
            const data = await getConteudoById(id_produto, id_conteudo);
            setConteudo(data);
        }else{
            alert("Voce não possui esse produto.");
            return ;
        }
    };

    // function to change the design view under 990 px
    const viewChange = () => {
        if (window.innerWidth < 990) {
            setMobileView(true);
        } else {
            setMobileView(false);
            updateSm(false);
        }
    };

    useEffect(() => {
        viewChange();
        window.addEventListener("load", viewChange);
        window.addEventListener("resize", viewChange);
        document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
            updateSm(false);
        });
        return () => {
            window.removeEventListener("resize", viewChange);
            window.removeEventListener("load", viewChange);
        };
    }, []);

    useEffect(() => {
        const verifyAccess = async () => {
            var has_access = await checkUserProduct(id_produto);
            if (has_access){
                const data1 = await getProdutoById(id_produto);
                setProduto(data1);
                const data2 = await getAssuntosById(id_produto);
                setAssuntos(data2);
            }
        }
        verifyAccess();
    }, [id_produto]);
  
    return (
        <React.Fragment>
        <Head title="User List - Profile"></Head>
        <Content>
        <Card className="">
        <div className="card-aside-wrap">
            <div className="card-aside-wrap">
            
            <div className="card-inner-group">
                <div className="card-inner">
                <div className="user-card">
                    <div className="user-info">
                    <span className="lead-text">{produto[0]?.produto_titulo || "Título não encontrado"}</span>
                    <span className="sub-text">{produto[0]?.produto_subtitulo || "Subtítulo não encontrado"}</span>
                    </div>    
                </div>
                </div>

                <div className="card-inner p-0">
                <ul className="link-list-menu">
                    {assuntos.length > 0 ? (
                    assuntos.map((item) => (
                        <li key={item.id} onClick={() => eclick(item.id, id_produto)}>
                        <Link to="">
                            {item.conteudo_concluido ? <Icon name="check-thick"></Icon> : <Icon name="spark-fill"></Icon>}
                            <span>Conteúdo {item.conteudo_ordem} - {item.conteudo_titulo}</span>
                        </Link>
                        </li>
                    ))
                    ) : (
                    <div className="ms-2">Nenhum assunto encontrado</div>
                    )}
                </ul>
                </div>
            </div>
            
            </div>

            <div className="card-aside card-aside-right">

                {conteudo.length > 0 ? (
                <div className="card-inner card-inner-lg">

                    {conteudo.map((item) => (
                    <BlockHead size="lg" key={item.id}>
                        <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h4">{item.conteudo_titulo}</BlockTitle>
                            <BlockDes>
                                <div>
                                    <ReactPlayer url={item.conteudo} controls={true} />
                                </div>
                                <p>{item.conteudo_texto}</p>
                            </BlockDes>
                        </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>
                    ))}
                </div>
                ) : (
                <div className="ms-2"></div>
                )}
            </div>
        </div>  
        </Card>
        </Content>
        </React.Fragment>
    );
};

export default AcessoPositivo;
