import User from "../../../images/avatar/b-sm.jpg";
import User2 from "../../../images/avatar/c-sm.jpg";
import User3 from "../../../images/avatar/a-sm.jpg";
import User4 from "../../../images/avatar/d-sm.jpg";

export const userData = [
  {
    id: 1,
    avatarBg: "purple",
    name: "Abu Bin Ishtiyak",
    displayName: "Ishtiak",
    dob: "10 Aug, 1980",
    role: "Customer",
    checked: false,
    email: "info@softnio.com",
    balance: "35040.34",
    phone: "818474958",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "10 Feb 2020",
    status: "Active",
    address: "2337 Kildeer Drive",
    state: "Kentucky",
    country: "Canada",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 2,
    avatarBg: "purple",
    image: User3,
    name: "Ashley Lawson",
    dob: "10 Sept, 1990",
    role: "Investor",
    email: "ashley@softnio.com",
    balance: "580.00",
    checked: false,
    phone: "1243941787",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "07 Feb 2020",
    status: "Pending",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 3,
    avatarBg: "info",
    name: "Joe Larson",
    dob: "19 Jan, 1985",
    role: "Customer",
    email: "larson@example.com",
    balance: "32000.34",
    checked: false,
    phone: "1686032320",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "04 Feb 2020",
    status: "Active",
    country: "England",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 4,
    avatarBg: "danger",
    name: "Jane Montgomery",
    dob: "24 April, 1985",
    role: "Subscriber",
    email: "jane84@example.com",
    balance: "0.00",
    checked: false,
    phone: "4392715360",
    emailStatus: "alert",
    kycStatus: "alert",
    lastLogin: "01 Feb 2020",
    status: "Suspend",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 5,
    avatarBg: "purple",
    name: "Frances Burns",
    dob: "30 May, 2000",
    role: "Manager",
    image: User,
    email: "frances@example.com",
    balance: "42.50",
    checked: false,
    phone: "6391303150",
    emailStatus: "pending",
    kycStatus: "error",
    lastLogin: "31 Jan 2020",
    status: "Active",
    country: "Bangladesh",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 6,
    avatarBg: "primary",
    name: "Alan Butler",
    dob: "10 Feb, 1997",
    role: "Investor",
    image: User2,
    email: "butler@example.com",
    balance: "440.34",
    checked: false,
    phone: "9633091706",
    emailStatus: "pending",
    kycStatus: "warning",
    lastLogin: "18 Jan 2020",
    status: "Inactive",
    country: "India",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 7,
    avatarBg: "warning",
    name: "Victoria Lynch",
    dob: "02 May, 1993",
    role: "Investor",
    email: "victoria@example.com",
    balance: "59400.68",
    checked: false,
    phone: "8119854846",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "15 Jan 2020",
    status: "Active",
    country: "China",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 8,
    avatarBg: "success",
    name: "Patrick Newman",
    dob: "15 Feb, 1997",
    role: "Customer",
    email: "patrick@example.com",
    balance: "30.00",
    checked: false,
    phone: "9422384474",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "08 Jan 2020",
    status: "Active",
    country: "India",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 9,
    avatarBg: "purple",
    name: "Jane Harris",
    dob: "28 Feb, 1985",
    role: "Customer",
    image: User4,
    email: "harris@example.com",
    balance: "5530.23",
    checked: false,
    phone: "1234472384",
    emailStatus: "pending",
    kycStatus: "pending",
    lastLogin: "02 Jan 2020",
    status: "Pending",
    country: "Vietnam",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 10,
    avatarBg: "purple",
    name: "Emma Walker",
    dob: "30 Dec, 1998",
    role: "Investor",
    email: "walker@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 11,
    avatarBg: "pink",
    name: "Lilja Peltola",
    dob: "30 Dec, 1998",
    role: "Investor",
    email: "lilja@example.com",
    balance: "105.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Canada",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 12,
    avatarBg: "secondary",
    name: "Annette Hunter",
    dob: "30 Dec, 1998",
    role: "Investor",
    email: "hunter@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "25 Dec 2019",
    status: "Pending",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 13,
    avatarBg: "pink",
    name: "Sara Koivisto",
    dob: "30 Dec, 1998",
    role: "Customer",
    email: "sara@example.com",
    balance: "165.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "rejected",
    kycStatus: "pending",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Russia",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 14,
    avatarBg: "blue",
    name: "Kianna Pham",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "kiana@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Suspend",
    country: "South Korea",
    designation: "Accountant",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 15,
    avatarBg: "pink",
    name: "Raymond Atkins",
    dob: "30 Dec, 1998",
    role: "Customer",
    image: User4,
    email: "sara@example.com",
    balance: "165.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "rejected",
    kycStatus: "pending",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Russia",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 16,
    avatarBg: "blue",
    name: "Amira Talley",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "amira@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Saudi Arabia",
    designation: "Lecturer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 17,
    avatarBg: "secondary",
    name: "Lana Steiner",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "steinar@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Pending",
    country: "Latvia",
    designation: "Accountant",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 18,
    avatarBg: "warning",
    name: "Joshua Mcnair",
    dob: "30 Dec, 1998",
    image: User4,
    role: "Admin",
    email: "joshua@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Suspend",
    country: "Ireland",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 19,
    avatarBg: "secondary",
    name: "Asiya Wolff",
    dob: "30 Dec, 1998",
    role: "Customer",
    email: "asia@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Latvia",
    designation: "Accountant",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 20,
    avatarBg: "warning",
    name: "Fox Mccloud",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "fox@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Suspend",
    country: "Ireland",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
];

export const notes = [
  {
    id: 1,
    text: "Aproin at metus et dolor tincidunt feugiat eu id quam. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean sollicitudin non nunc vel pharetra.",
    date: "November 18, 2019",
    time: "5:34",
    company: "Softnio",
  },
  {
    id: 2,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eleifend libero semper metus aliquam tempus. Sed efficitur elit et ligula lobortis",
    date: "December 27, 2019",
    time: "7:00",
    company: "Softnio",
  },
];

export const filterStatus = [
  { value: "Active", label: "Active" },
  { value: "Pending", label: "Pending" },
  { value: "Suspend", label: "Suspend" },
];

export const filterRole = [
  { value: "investor", label: "Investor" },
  { value: "seller", label: "Seller" },
  { value: "buyer", label: "Buyer" },
];

export const countryOptions = [
  { value: "Canada", label: "Canada" },
  { value: "USA", label: "USA" },
  { value: "India", label: "India" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "France", label: "France" },
  { value: "England", label: "England" },
];

export const tipoContaBanco = [
  { value: "Conta Corrente", label: "Conta Corrente" },
  { value: "Conta Poupança", label: "Conta Poupança" },
  { value: "Conta Salário", label: "Conta Salário" },
];

export const tipoChavePix = [
  { value: "CPF/CNPJ", label: "CPF/CNPJ" },
  { value: "E-mail", label: "E-mail" },
  { value: "Telefone Celular", label: "Telefone Celular" },
  { value: "Chave Aleatória", label: "Chave Aleatória" },
];

export const listaBancosBrasileiros = [
{ value: "001", label: "001 - Banco do Brasil" },
{ value: "003", label: "003 - Banco da Amazônia S.A" },
{ value: "004", label: "004 - Banco do Nordeste do Brasil S.A" },
{ value: "012", label: "012 - Banco Inbursa S.A" },
{ value: "014", label: "014 - State Street Brasil S.A. - Banco Comercial" },
{ value: "016", label: "016 - Cooperativa de Crédito Mútuo dos Despachantes de Trânsito de SC e RS" },
{ value: "021", label: "021 - Bank of America Merrill Lynch Banco Múltiplo S.A" },
{ value: "024", label: "024 - Banco Bandepe S.A" },
{ value: "025", label: "025 - Banco Alfa S.A" },
{ value: "029", label: "029 - Banco Itaú BBA S.A" },
{ value: "033", label: "033 - Banco Santander (Brasil) S.A" },
{ value: "036", label: "036 - Banco Bradesco BBI S.A" },
{ value: "037", label: "037 - Banco do Estado do Pará S.A" },
{ value: "040", label: "040 - Banco Cargill S.A" },
{ value: "062", label: "062 - Hipercard Banco Múltiplo S.A" },
{ value: "063", label: "063 - Banco Bradescard S.A" },
{ value: "064", label: "064 - Goldman Sachs do Brasil Banco Múltiplo S.A" },
{ value: "065", label: "065 - Banco AndBank (Brasil) S.A" },
{ value: "069", label: "069 - Banco Crefisa S.A" },
{ value: "070", label: "070 - Banco de Brasília S.A" },
{ value: "074", label: "074 - Banco J. Safra S.A" },
{ value: "075", label: "075 - Banco ABN Amro S.A" },
{ value: "076", label: "076 - Banco KDB do Brasil S.A" },
{ value: "077", label: "077 - Banco Inter S.A" },
{ value: "081", label: "081 - BancoSeguro S.A" },
{ value: "082", label: "082 - Banco Topázio S.A" },
{ value: "083", label: "083 - Banco da China Brasil S.A" },
{ value: "085", label: "085 - Cooperativa Central de Crédito - Ailos" },
{ value: "088", label: "088 - Banco Randon S.A" },
{ value: "089", label: "089 - Credisan Cooperativa de Crédito" },
{ value: "092", label: "092 - BRK S.A. Crédito, Financiamento e Investimento" },
{ value: "093", label: "093 - Pólocred Sociedade de Crédito ao Microempreendedor e à Empresa de Pequeno Porte" },
{ value: "095", label: "095 - Travelex Banco de Câmbio S.A" },
{ value: "097", label: "097 - Credisis - Central de Cooperativas de Crédito Ltda" },
{ value: "104", label: "104 - Caixa Econômica Federal" },
{ value: "107", label: "107 - Banco Bocom BBM S.A" },
{ value: "119", label: "119 - Banco Western Union do Brasil S.A" },
{ value: "121", label: "121 - Banco Agibank S.A" },
{ value: "124", label: "124 - Banco Woori Bank do Brasil S.A" },
{ value: "128", label: "128 - MS Bank S.A. Banco de Câmbio" },
{ value: "129", label: "129 - UBS Brasil Banco de Investimento S.A" },
{ value: "130", label: "130 - Caruana S.A. - Sociedade de Crédito, Financiamento e Investimento" },
{ value: "136", label: "136 - Unicred do Brasil" },
{ value: "140", label: "140 - Easynvest - Título Corretora de Valores S.A" },
{ value: "142", label: "142 - Broker Brasil Corretora de Câmbio Ltda" },
{ value: "143", label: "143 - Treviso Corretora de Câmbio S.A" },
{ value: "144", label: "144 - Bexs Banco de Câmbio S/A" },
{ value: "145", label: "145 - Lecca Crédito, Financiamento e Investimento S/A" },
{ value: "146", label: "146 - Guitta Corretora de Câmbio Ltda" },
{ value: "149", label: "149 - Facta Financeira S.A. - Crédito Financiamento e Investimento" },
{ value: "157", label: "157 - ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda" },
{ value: "159", label: "159 - Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor" },
{ value: "163", label: "163 - Commerzbank Brasil S.A. - Banco Múltiplo" },
{ value: "169", label: "169 - Banco Olé Bonsucesso Consignado S.A" },
{ value: "197", label: "197 - Stone Pagamentos S.A" },
{ value: "208", label: "208 - Banco BTG Pactual S.A" },
{ value: "212", label: "212 - Banco Original S.A" },
{ value: "213", label: "213 - Banco Arbi S.A" },
{ value: "217", label: "217 - Banco John Deere S.A" },
{ value: "218", label: "218 - Banco BS2 S.A" },
{ value: "222", label: "222 - Banco Credit Agricole Brasil S.A" },
{ value: "224", label: "224 - Banco Fibra S.A" },
{ value: "233", label: "233 - Banco Cifra S.A" },
{ value: "237", label: "237 - Banco Bradesco S.A" },
{ value: "241", label: "241 - Banco Clássico S.A" },
{ value: "243", label: "243 - Banco Máxima S.A" },
{ value: "246", label: "246 - Banco ABC Brasil S.A" },
{ value: "249", label: "249 - Banco Investcred Unibanco S.A" },
{ value: "250", label: "250 - BCV - Banco de Crédito e Varejo S.A" },
{ value: "253", label: "253 - Bexs Corretora de Câmbio S/A" },
{ value: "254", label: "254 - Paraná Banco S.A" },
{ value: "260", label: "260 - Nu Pagamentos S.A" },
{ value: "265", label: "265 - Banco Fator S.A" },
{ value: "266", label: "266 - Banco Cédula S.A" },
{ value: "300", label: "300 - Banco de La Nacion Argentina" },
{ value: "318", label: "318 - Banco BMG S.A" },
{ value: "319", label: "319 - Banco Omnilife do Brasil S.A" },
{ value: "320", label: "320 - China Construction Bank (Brasil) Banco Múltiplo S/A" },
{ value: "341", label: "341 - Itaú Unibanco S.A" },
{ value: "364", label: "364 - Gerencianet S.A" },
{ value: "365", label: "365 - Socred S.A. - Sociedade de Crédito ao Microempreendedor e à Empresa de Pequeno Porte" },
{ value: "366", label: "366 - Banco Societe Generale Brasil S.A" },
{ value: "370", label: "370 - Mizuho Bank Ltd" },
{ value: "376", label: "376 - Banco J. P. Morgan S.A" },
{ value: "389", label: "389 - Banco Mercantil do Brasil S.A" },
{ value: "394", label: "394 - Banco Bradesco Financiamentos S.A" },
{ value: "399", label: "399 - Kirton Bank S.A. - Banco Múltiplo" },
{ value: "412", label: "412 - Banco Capital S.A" },
{ value: "422", label: "422 - Banco Safra S.A" },
{ value: "456", label: "456 - Banco MUFG Brasil S.A" },
{ value: "464", label: "464 - Banco Sumitomo Mitsui Brasileiro S.A" },
{ value: "473", label: "473 - Banco Caixa Geral - Brasil S.A" },
{ value: "477", label: "477 - Citibank N.A" },
{ value: "479", label: "479 - Banco ItauBank S.A" },
{ value: "487", label: "487 - Deutsche Bank S.A. - Banco Alemão" },
{ value: "488", label: "488 - JPMorgan Chase Bank, National Association" },
{ value: "492", label: "492 - ING Bank N.V" },
{ value: "494", label: "494 - Banco de La Republica Oriental del Uruguay" },
{ value: "495", label: "495 - Banco de La Provincia de Buenos Aires" },
{ value: "505", label: "505 - Banco Credit Suisse (Brasil) S.A" },
{ value: "545", label: "545 - Senso Corretora de Câmbio e Valores Mobiliários S.A" },
{ value: "600", label: "600 - Banco Luso Brasileiro S.A" },
{ value: "604", label: "604 - Banco Industrial do Brasil S.A" },
{ value: "610", label: "610 - Banco VR S.A" },
{ value: "611", label: "611 - Banco Paulista S.A" },
{ value: "612", label: "612 - Banco Guanabara S.A" },
{ value: "613", label: "613 - Omni Banco S.A" },
{ value: "623", label: "623 - Banco Pan S.A" },
{ value: "626", label: "626 - Banco Ficsa S.A" },
{ value: "630", label: "630 - Banco Smartbank S.A" },
{ value: "633", label: "633 - Banco Rendimento S.A" },
{ value: "634", label: "634 - Banco Triângulo S.A" },
{ value: "637", label: "637 - Banco Sofisa S.A" },
{ value: "641", label: "641 - Banco Alvorada S.A" },
{ value: "643", label: "643 - Banco Pine S.A" },
{ value: "652", label: "652 - Itaú Unibanco Holding S.A" },
{ value: "653", label: "653 - Banco Indusval S.A" },
{ value: "654", label: "654 - Banco A.J. Renner S.A" },
{ value: "655", label: "655 - Banco Votorantim S.A" },
{ value: "707", label: "707 - Banco Daycoval S.A" },
{ value: "712", label: "712 - Banco Ourinvest S.A" },
{ value: "719", label: "719 - Banif - Banco Internacional do Funchal (Brasil) S.A" },
{ value: "735", label: "735 - Banco Neon S.A" },
{ value: "739", label: "739 - Banco Cetelem S.A" },
{ value: "740", label: "740 - Banco Barclays S.A" },
{ value: "741", label: "741 - Banco Ribeirão Preto S.A" },
{ value: "743", label: "743 - Banco Semear S.A" },
{ value: "745", label: "745 - Banco Citibank S.A" },
{ value: "746", label: "746 - Banco Modal S.A" },
{ value: "747", label: "747 - Banco RaboBank International Brasil S.A" },
{ value: "748", label: "748 - Banco Cooperativo Sicredi S.A" },
{ value: "751", label: "751 - Scotiabank Brasil S.A. Banco Múltiplo" },
{ value: "752", label: "752 - BNP Paribas Brasil S.A" },
{ value: "753", label: "753 - Novo Banco Continental S.A. - Banco Múltiplo" },
{ value: "754", label: "754 - Banco Sistema S.A" },
{ value: "755", label: "755 - Bank of America Merrill Lynch Banco Múltiplo S.A" },
{ value: "756", label: "756 - Banco Cooperativo do Brasil S.A. - Bancoob" },
];
