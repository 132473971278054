import Indicante1 from "../images/indiqueganhe/indicante1.svg";
import Indicante2 from "../images/indiqueganhe/indicante2.svg";
import Indicante3 from "../images/indiqueganhe/indicante3.svg";

export const IndiqueGanheData = [
  {
    id: 1,
    title: "Indicante Basic",
    logo: Indicante1,
    desc: "Ganhe comissões por cada ativação* de cliente indicado",
    amount: 50,
    descBonus: "Por cada ativação* de cliente indepentende do período",
    exemplo1: 5,
    exemplo2: 250,
    exemplo3: "Somente Comissões",
    tags: false,
  },
  {
    id: 2,
    title: "Indicante Pro",
    logo: Indicante2,
    desc: "Ganhe bônus por meta de ativações* de clientes atingidas",
    amount: 500,
    descBonus: "10 ativações* de clientes durante o mesmo mês vigente corrido",
    exemplo1: 10,
    exemplo2: 1000,
    exemplo3: "Comissões + Bônus Pro",
    tags: false,
  },
  {
    id: 3,
    title: "Indicante Master",
    logo: Indicante3,
    desc: "Ganhe bônus por meta de ativações* de clientes atingidas",
    amount: 2000,
    descBonus: "30 ativações* de clientes durante o mesmo mês vigente corrido",
    exemplo1: 30,
    exemplo2: 4000,
    exemplo3: "Comissões + Bônus Pro + Bônus Master",
    tags: true,
  },
];
