import React, { useState, useEffect } from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import CampaignInfoCard from "../components/partials/campaign/campaign-info-card/CampaignInfoCard";
import { requests } from "../services/api";
import SaleRevenue from "../components/partials/sales/sale-revenue/SaleRevenue";
import ActiveSubscription from "../components/partials/sales/active-subscription/ActiveSubscription";
import AvgSubscription from "../components/partials/sales/avg-subscription/AvgSubscription";
import NewsUsers from "../components/partials/sales/new-users/User";

const CampaignHomePage = () => {
  const [sm, updateSm] = useState(false);
  const [information, setInformation] = useState([]);

  useEffect(() => {
    fetchIndicate();
  }, []);

  const fetchIndicate = async () => {
      try {
        const response = await requests("https://solutions.bradosmart.com.br/app/api/indication/stats", "GET", {});
        const data = await response.json();
        setInformation(data.message);
        console.log(data)
      } catch (error) {console.log()}
  };

  return (
    <React.Fragment>
      <Head title="Campaign Dashboard" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Resumo Indicante</BlockTitle>
              <BlockDes className="text-soft">
                <p>Painel com as principais Informações para você indicante </p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle" style={{ display: 'none' }}>
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Button className="btn-dim btn-white btn-outline-light">
                        <Icon name="plus"></Icon>
                        <span><span className="d-md-none">Add</span><span className="d-none d-md-block">Add Campaign</span></span>
                      </Button>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button className="btn-primary">
                        <Icon name="reports"></Icon>
                        <span>Reports</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col lg="3" sm="6">
              <CampaignInfoCard 
                theme="primary"
                title="Valor Recebido"
                amount={information.indicate ? information.indicate.value_receipt : "R$ 0"}
                change="Últimos 15 dias"
                direction="up"
                chart="line"
                chartData={{
                    labels : information.indicate ? information.indicate.receipt_date : [],
                    dataUnit : 'Running Campaign',
                    datasets : [{
                        label : "People",
                        lineTension: .3,
                        borderWidth: 1,
                        fill:true,
                        color: "#fff",
                        backgroundColor: "rgba(255,255,255,.15)",
                        borderColor: "#fff",
                        pointBorderColor: "transparent",
                        pointBackgroundColor: "transparent",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#fff",
                        pointBorderWidth: 1,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 1,
                        pointRadius: 4,
                        pointHitRadius: 4,
                        data: information.indicate ? information.indicate.receipt_date_values : []
                    }]
                }}
              />
            </Col>
            <Col lg="3" sm="6">
              <CampaignInfoCard 
                theme="info"
                title="Disponível para saque"
                amount= {information.indicate ? information.indicate.withdraw_value : "R$ 0"}
                change="Últimos 15 dias"
                direction="up"
                chart="line"
                chartData={{
                    labels : information.indicate ? information.indicate.withdraw_date : [],
                    dataUnit : 'Audience',
                    datasets : [{
                        label : "People",
                        lineTension: 0,
                        borderWidth: 1,
                        fill:true,
                        color: "#fff",
                        backgroundColor: "rgba(255,255,255,.15)",
                        borderColor: "#fff",
                        pointBorderColor: "transparent",
                        pointBackgroundColor: "transparent",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#fff",
                        pointBorderWidth: 1,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 1,
                        pointRadius: 4,
                        pointHitRadius: 4,
                        data: information.indicate ? information.indicate.withdraw_date_values : []
                    }]
                }}
              />
            </Col>
            <Col lg="3" sm="6">
              <CampaignInfoCard 
                theme="warning"
                title="Possíveis ganhos"
                amount={information.indicate ? information.indicate.gain_value : "R$ 0"}
                change="Últimos 15 dias"
                direction="up"
                chart="bar"
                chartData={{
                    labels : information.indicate ? information.indicate.gain_date : [],
                    datasets : [{
                        label : "People",
                        borderWidth: 2,
                        borderColor: 'transparent',
                        hoverBorderColor : 'transparent',
                        borderSkipped : 'bottom',
                        barPercentage : .8,
                        categoryPercentage : .8,
                        backgroundColor : ["rgba(255,255,255,.2)","rgba(255,255,255,.2)","rgba(255,255,255,.2)","rgba(255,255,255,.2)","rgba(255,255,255,.2)","rgba(255,255,255,.2)","rgba(255,255,255,.2)","rgba(255,255,255,.2)","rgba(255,255,255,.2)","rgba(255,255,255,.2)","rgba(255,255,255,.2)","rgba(255,255,255,.2)","rgba(255,255,255,.2)","rgba(255,255,255,.2)","rgba(255,255,255,.2)"],
                        data: information.indicate ? information.indicate.gain_date_values : []
                    }]
                }}
              />
            </Col>
            <Col lg="3" sm="6">
              <CampaignInfoCard 
                theme="danger"
                title="Propostas Pendentes"
                amount={information.indicate ? information.indicate.proposal_value : "R$ 0"}
                change="Últimos 15 dias"
                direction="up"
                chart="line"
                chartData={{
                    labels : information.indicate ? information.indicate.proposal_date : [],
                    dataUnit : 'Subscriber',
                    datasets : [{
                        label : "People",
                        lineTension: .3,
                        borderWidth: 1,
                        fill:true,
                        color: "#fff",
                        backgroundColor: "rgba(255,255,255,.15)",
                        borderColor: "#fff",
                        pointBorderColor: "transparent",
                        pointBackgroundColor: "transparent",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#fff",
                        pointBorderWidth: 1,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 1,
                        pointRadius: 4,
                        pointHitRadius: 4,
                        data: information.indicate ? information.indicate.proposal_date_values : []
                    }]
                }}
              />
            </Col>
            
            <Col xxl="7">
              <Row className="g-gs">
                <Col lg="6" xxl="12">
                  <PreviewAltCard>
                    <SaleRevenue information />
                  </PreviewAltCard>
                </Col>
                <Col lg="6" xxl="12">
                  <Row className="g-gs">
                    <Col sm="6" lg="12" xxl="6">
                      <PreviewAltCard>
                        <ActiveSubscription />
                      </PreviewAltCard>
                    </Col>
                    <Col sm="6" lg="12" xxl="6">
                      <PreviewAltCard>
                        <AvgSubscription />
                      </PreviewAltCard>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xxl="5">
              <PreviewAltCard className="h-100">
                <NewsUsers indications_data={Array.isArray(information.last_indications?.data) ? information.last_indications.data : []}/>
              </PreviewAltCard>
            </Col>

          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default CampaignHomePage;
