import React, { useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Badge, Button, CardHeader, CardFooter, CardImg, CardText, CardBody, CardTitle, CardSubtitle, CardLink, } from "reactstrap";
import { Block, BlockDes, BlockHead, BlockHeadContent, BlockTitle, BlockBetween, PreviewCard, Row, Col } from "../components/Component";
import { Card } from "reactstrap";
import IndiqueGanheImagem from "../images/indiqueganhe/indique-ganhe.jpg";
import Accordion from "../components/partials/accordian/Preview";
import { IndiqueGanheData } from "./IndiqueGanheData";

const IndiqueGanhe = ({ ...props }) => {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check initially

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const containerStyle = isMobile
    ? { display: 'block', justifyContent: 'normal', textAlign: 'left' }
    : { display: 'flex', justifyContent: 'center', textAlign: 'center' };

  const styledDiv = {
    backgroundColor: '#fd7104',
    padding: '20px',
    margin: '20px 0',
    borderRadius: '5px'
  };

  return (
    <React.Fragment>
      <Head title="Terms &amp; Conditions" />
      
      <Content>
        <div className="content-page m-auto" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
          <Block>
            <PreviewCard className="">
              <div className="entry">

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', textAlign: 'center'}}>
                  <h2>Indique e Ganhe</h2>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px', textAlign: 'center'}}>
                  <h5>Ganhe uma renda extra online sem sair de casa</h5>
                </div>

                <img src={IndiqueGanheImagem} alt="" />

                <h4>Como Funciona?</h4>
                <p>Ao assinar o contrato de indicante você ganha acesso ao nosso portal onde consegue gerar seus links de indicação</p>

                  <div style={{marginBottom: '30px', marginLeft: '30px', marginRight: '30px'}}>
                    <Row className="g-gs">
                      <Col sm="4">
                        <Card className="" inverse color="info">
                          <CardHeader>Primeiro Passo</CardHeader>
                          <CardBody className="card-inner">
                            <CardTitle tag="h5">Divulgue Seu Link e Cupom</CardTitle>
                            <CardText>
                              Envie seu link de indicação e cupom de desconto para a maior quantidade de pessoas que você conseguir
                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm="4">
                        <Card className="" inverse color="secondary">
                          <CardHeader>Segundo Passo</CardHeader>
                          <CardBody className="card-inner">
                            <CardTitle tag="h5">Acompanhe Seus Indicados</CardTitle>
                            <CardText>
                              Acompanhe no Portal do Indicante as informações em tempo real das suas indicações
                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm="4">
                      <Card className="" inverse color="primary">
                          <CardHeader>Terceiro Passo</CardHeader>
                          <CardBody className="card-inner">
                            <CardTitle tag="h5">Processo de Vendas</CardTitle>
                            <CardText>
                              Nosso time de consultores assume a partir da sua indicação para dar sequência no atendimento e possível vendas
                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>

                <h4>Níveis de Indicante</h4>
                <p>
                  Você ganha comissões por cada venda realizada e ainda pode ganhar bônus ao atingir metas mensais
                </p>

                <Row className="g-gs">
                  <div style={containerStyle}>
                  {IndiqueGanheData.map((item) => {
                    return (
                      <Col md={6} xxl={3} key={item.id}>
                        <Card className={`pricing text-center`}>
                          {item.tags && (
                            <Badge color="primary" className="pricing-badge">
                              Novidade
                            </Badge>
                          )}
                          <div className="pricing-body">
                            <div className="pricing-media">
                              <img src={item.logo} alt="" />
                            </div>
                            <div className="pricing-title w-220px mx-auto">
                              <h5 className="title">{item.title}</h5>
                              <span className="sub-text">{item.desc}</span>
                            </div>
                            <div className="pricing-amount">
                              <div className="amount">
                                <h3>R${item.amount}</h3>
                              </div>
                              <span className="sub-text">{item.descBonus}</span>
                            </div>
                            <div className="pricing-title w-220px mx-auto">
                              <br />
                              <h5 className="sub-text" style={{color: '#fd7104'}}>Exemplo de Ganho:</h5>
                                <span className="sub-text">{item.exemplo1} ativações* no mês</span>
                                <p className="sub-text">Ganho total de <strong>R${item.exemplo2}</strong></p>
                                <span className="sub-text">{item.exemplo3}</span>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
                  </div>
                <p>
                  <strong>*Ativação de Cliente: </strong>
                  Indicação se torna um cliente ativo 10 dias após o pagamento da compra e conclusão dos procedimentos contratuais.
                </p><br />

                </Row>

                <div style={styledDiv}>
                  <h2 style={{color: 'white'}}>Indique agora mesmo!</h2>
                  <a href="" class="btn btn-xl btn-secondary" style={{marginTop: '10px', marginBottom: '10px'}}>Clique Aqui</a>
                </div>

                <div style={{marginTop: '60px', marginBottom: '50px', textAlign: 'center'}}>
                  <h4>Perguntas frequentes</h4>
                  <Accordion/>
                </div>

              </div>
            </PreviewCard>
          </Block>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default IndiqueGanhe;
