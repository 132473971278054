import { db, auth } from "../firebase/filebase";
import { 
  collection,
  doc,
  addDoc,
  getDoc,
  setDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";

export async function getProdutoById(id_produto) {
  const my_list = [];  
  
  try {
    const docRef = doc(db, "produtos", id_produto);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const my_id_document = docSnap.id;
      const my_key = {
        id: my_id_document, // Adicionando o id do documento
        ...docSnap.data() // Inclui os dados do documento
      };

      // Adiciona o documento à lista
      my_list.push(my_key);
    } else {
      console.log("Nenhum documento encontrado!");
    }

    console.log(my_list);
    return my_list;

  } catch (error) {
    console.error("Erro ao buscar documento: ", error);
  }
}

export async function getAssuntosById(id_produto) {
  const my_list = [];  
  try {
    const collectionRef = collection(db, "produtos", id_produto, "conteudos");
    const queryRef = query(collectionRef, orderBy("conteudo_ordem", "asc"));
    const querySnapshot = await getDocs(queryRef);
    
    querySnapshot.forEach((doc) => {
      const my_id_document = doc.id;
      const my_key = {
        id: my_id_document, // Adicionando o id do documento
        ...doc.data() // Inclui os dados do documento
      };

      // Adiciona o documento à lista
      my_list.push(my_key);
    });

    if (my_list.length === 0) {
      console.log("Nenhum documento encontrado!");
    } else {
      console.log(my_list);
    }

    return my_list;

  } catch (error) {
    console.error("Erro ao buscar documentos: ", error);
  }
}

export async function getConteudoById(id_produto, id_conteudo) {
    const my_list = [];  
    try {
      const docRef = doc(db, "produtos", id_produto, "conteudos", id_conteudo);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const my_id_document = docSnap.id;
        const my_key = {
          id: my_id_document, // Adicionando o id do documento
          ...docSnap.data() // Inclui os dados do documento
        };
  
        // Adiciona o documento à lista
        my_list.push(my_key);
      } else {
        console.log("Nenhum documento encontrado!");
      }
  
      console.log(my_list);
      return my_list;
  
    } catch (error) {
      console.error("Erro ao buscar documentos: ", error);
    }
  }


  export async function checkUserProduct(id_produto) {
    try {    
        const q = query(
            collection(db, "usuarios", auth.currentUser.uid, "produtos"),
            where('id_produto', '==', id_produto)
        );
        const docSnap = await getDocs(q);
        var has = false;
        docSnap.forEach((doc) => {
            if(doc.exists()){
                has = true;
            }
        });
        if(has){
            return true
        }
    } catch (error) {
      console.error("Erro ao buscar documentos: ", error);
    }
    return false
  }