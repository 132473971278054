import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import { requests } from "../services/api"

// export async function getIndicate(auth){
//     const user = auth.currentUser;
//     var token = false;
//     if (user) {
//         token = await user.getIdToken();
//     }
//     if(!token){
//         return false;
//     }url, method, data, params, headers

//     requests("https://solutions.bradosmart.com.br/app/api/account-system/manage", "GET", {}, "", {})

// }


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEoroCUcPe8FSPWg2QbFBHa5HO5TA18ac",
  authDomain: "solutionstech-b3354.firebaseapp.com",
  projectId: "solutionstech-b3354",
  storageBucket: "solutionstech-b3354.appspot.com",
  messagingSenderId: "595675848377",
  appId: "1:595675848377:web:132af1fa1251e8fa714568"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const auth = getAuth(app);

export default app;