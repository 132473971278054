export const menu = [
    {
        icon: "building",
        text: "Empresa",
        active: false,
        subMenu: [
          {
            text: "Positivo",
            link: "/",
          },
        ],
      },

  {
    heading: "Cliente",
  },
  {
    icon: "spark-fill",
    text: "Meus Produtos",
    link: "/meus-produtos",
  },
  {
    icon: "cart",
    text: "Loja Positivo",
    link: "/loja-positivo",
  },
  {
    icon: "thumbs-up",
    text: "Jornada Positivo",
    link: "/jornada-positivo",
  },
  {
    icon: "coin",
    text: "Indique e Ganhe",
    link: "/indique-ganhe",
  },
  { heading: "Indicante" },
  {
    icon: "presentation",
    text: "Resumo Indicante",
    link: "/",
  },
  {
    icon: "users",
    text: "Minhas Indicações",
    link: "/minhas-indicacoes",
  },
  {
    icon: "user-add",
    text: "Cadastrar Indicação",
    link: "/cadastrar-indicacao",
  },
  {
    icon: "growth",
    text: "Links e Cupons",
    link: "/links-cupons",
  },
  {
    icon: "coins",
    text: "Meu Saldo",
    link: "/analytics",
  },
  {
    heading: "Ajustes",
  },
  {
    icon: "setting",
    text: "Configurações",
    active: false,
    subMenu: [
      {
        text: "Perfil Positivo",
        link: "/user-profile-regular",
      },
      {
        text: "Registro de Acessos",
        link: "/user-profile-activity",
      },
      {
        text: "Configurações de Segurança",
        link: "/user-profile-setting",
      },
    ],
  },
];

export const headermenu = [
  {
  },
];

export const copywritermenu = [
  {
  },
];

export const copywriterheadermenu = [
  {
  },
];

export default menu;
