import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Icon, UserAvatar } from "../../../components/Component";
import { findUpper } from "../../../utils/Utils";
import {  DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";

const UserProfileAside = ({updateSm,sm}) => {
  const [profileName, setProfileName] = useState("Rodrigo Boss");
  
  useEffect(() => {
    sm ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
  }, [sm])
  
  return (
    <div className="card-inner-group">
    <div className="card-inner">
        <div className="user-card">
        <UserAvatar text={findUpper(profileName)} theme="primary" />
        <div className="user-info">
            <span className="lead-text">{profileName}</span>
            <span className="sub-text">rodrigo@boss.com.br</span>
        </div>
        <div className="user-action" style={{ display: 'none' }}>
            <UncontrolledDropdown>
            <DropdownToggle tag="a" className="btn btn-icon btn-trigger me-n2">
                <Icon name="more-v"></Icon>
            </DropdownToggle>
            <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                <li>
                    <DropdownItem
                    tag="a"
                    href="#dropdownitem"
                    onClick={(ev) => {
                        ev.preventDefault();
                    }}
                    >
                    <Icon name="camera-fill"></Icon>
                    <span>Mudar Foto</span>
                    </DropdownItem>
                </li>
                </ul>
            </DropdownMenu>
            </UncontrolledDropdown>
        </div>
        </div>
    </div>
    <div className="card-inner p-0">
        <ul className="link-list-menu">
        <li onClick={() => updateSm(false)}>
            <Link
            to={`${process.env.PUBLIC_URL}/user-profile-regular`}
            className={
                window.location.pathname === `${process.env.PUBLIC_URL}/user-profile-regular` ? "active" : ""
            }
            >
            <Icon name="user-fill-c"></Icon>
            <span>Perfil Positivo</span>
            </Link>
        </li>
        <li onClick={() => updateSm(false)}>
            <Link
            to={`${process.env.PUBLIC_URL}/user-profile-activity`}
            className={
                window.location.pathname === `${process.env.PUBLIC_URL}/user-profile-activity` ? "active" : ""
            }
            >
            <Icon name="activity-round-fill"></Icon>
            <span>Registro de Acessos</span>
            </Link>
        </li>
        <li onClick={() => updateSm(false)}>
            <Link
            to={`${process.env.PUBLIC_URL}/user-profile-setting`}
            className={
                window.location.pathname === `${process.env.PUBLIC_URL}/user-profile-setting` ? "active" : ""
            }
            >
            <Icon name="lock-alt-fill"></Icon>
            <span>Configurações de Segurança</span>
            </Link>
        </li>
        <li onClick={() => updateSm(false)}>
            <Link
            to={`${process.env.PUBLIC_URL}/historico-compras`}
            className={
                window.location.pathname === `${process.env.PUBLIC_URL}/historico-compras` ? "active" : ""
            }
            >
            <Icon name="cart-fill"></Icon>
            <span>Histórico de Compras</span>
            </Link>
        </li>
        <li onClick={() => updateSm(false)}>
            <Link
            to={`${process.env.PUBLIC_URL}/termos-contratos`}
            className={
                window.location.pathname === `${process.env.PUBLIC_URL}/termos-contratos` ? "active" : ""
            }
            >
            <Icon name="file"></Icon>
            <span>Termos de Uso e Contratos</span>
            </Link>
        </li>
        <li onClick={() => updateSm(false)}>
            <Link
            to={`${process.env.PUBLIC_URL}/logout`}
            className={
                window.location.pathname === `${process.env.PUBLIC_URL}/logout` ? "active" : ""
            }
            >
            <Icon name="wallet-out"></Icon>
            <span>Sair da Conta</span>
            </Link>
        </li>
        </ul>
    </div>
    </div>
  );
};

export default UserProfileAside;
