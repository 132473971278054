import React, { useState, useEffect } from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BlockBetween,
  PreviewCard,
  ReactDataTable,
  TooltipComponent,
} from "../components/Component";
import { BalanceBarChart, DepositBarChart, WithdrawBarChart } from "../components/partials/charts/invest/InvestChart";
import { DataTableData, dataTableColumns, dataTableColumns2, userData } from "../pages/components/table/TableData";
import { requests } from "../services/api";



const MinhasIndicacoes = () => {
    const [indications, setIndications] = useState([]);

    useEffect(() => {
        fetchIndications();
    }, []);
  
    const fetchIndications = async () => {
        try {
          const response = await requests("https://solutions.bradosmart.com.br/app/api/indication/manager", "GET", {});
          const data = await response.json();
          setIndications(data.message);
        } catch (error) {}
    };
    
  return (
    <React.Fragment>
      <Head title="Sales Dashboard"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Minhas Indicações
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Acompanhe suas indicações para faturar cada vez mais</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        
        <Block>
          <Row className="g-gs">

          <Col md="4">
              <PreviewAltCard className="card-full">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="title">Total Geral de Indicações</h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="invest-deposit"
                      text="Total Deposited"
                    ></TooltipComponent>
                  </div>
                </div>
                <div className="nk-sale-data">
                  <span className="amount">
                    {indications.total_indications}
                  </span>
                  
                </div>
                <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                      <div className="title">Últimos 30 dias</div>
                      <span className="amount">
                        {indications.total_indications_last_30}{" "}
                      </span>
                      <span className={parseFloat(indications.percentage_change_indications_30_days) > 0 ? "change up text-success" : "change down text-danger"}>
                        <Icon name={parseFloat(indications.percentage_change_indications_30_days) > 0 ? "arrow-long-up" : "arrow-long-down"}></Icon>{indications.percentage_change_indications_30_days}%
                      </span>
                    </div>
                    <div className="invest-data-history">
                      <div className="title">Últimos 7 dias</div>
                      <span className="amount">
                      {indications.total_indications_last_7}{" "}
                      </span>
                      <span className={parseFloat(indications.percentage_change_indications_7_days) > 0 ? "change up text-success" : "change down text-danger"}>
                      <Icon name={parseFloat(indications.percentage_change_indications_7_days) > 0 ? "arrow-long-up" : "arrow-long-down"}></Icon>{indications.percentage_change_indications_7_days}%
                      </span>
                    </div>
                  </div>
                  <div className="invest-data-ck">
                    {/* <DepositBarChart /> */}
                  </div>
                </div>
              </PreviewAltCard>
            </Col>

            <Col md="4">
              <PreviewAltCard className="card-full">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="title">Total Geral de Vendas</h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="invest-withdraw"
                      text="Total Withdrawn"
                    ></TooltipComponent>
                  </div>
                </div>
                <div className="nk-sale-data">
                  <span className="amount">
                    {indications.total_sales}{" "}
                  </span>
                </div>
                <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                      <div className="title">Últimos 30 dias</div>
                      <span className="amount">
                      {indications.total_sales_last_30}{" "}
                      </span>
                      <span className={parseFloat(indications.percentage_change_sales_30_days) > 0 ? "change up text-success" : "change down text-danger"}>
                      <Icon name={parseFloat(indications.percentage_change_sales_30_days) > 0 ? "arrow-long-up" : "arrow-long-down"}></Icon>{indications.percentage_change_sales_30_days}%
                      </span>
                    </div>
                    <div className="invest-data-history">
                      <div className="title">Últimos 7 dias</div>
                      <span className="amount">
                        {indications.total_sales_last_7}{" "}
                      </span>
                      <span className={parseFloat(indications.percentage_change_sales_7_days) > 0 ? "change up text-success" : "change down text-danger"}>
                      <Icon name={parseFloat(indications.percentage_change_sales_7_days) > 0 ? "arrow-long-up" : "arrow-long-down"}></Icon>{indications.percentage_change_sales_7_days}%
                      </span>
                    </div>
                  </div>
                  <div className="invest-data-ck">
                    {/* <WithdrawBarChart /> */}
                  </div>
                </div>
              </PreviewAltCard>
            </Col>

            <Col md="4">
              <PreviewAltCard className="card-full">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="title">Taxa Geral de Conversão</h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="invest-balance"
                      text="Total Balance"
                    ></TooltipComponent>
                  </div>
                </div>
                <div className="nk-sale-data">
                  <span className="amount">
                    {indications.conversion}%{" "}
                  </span>
                </div>
                <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                      <div className="title">Últimos 30 dias</div>
                      <span className="amount">
                        {indications.conversion_30_days}%{" "}
                      </span>
                      <span className="change down text-danger">
                        {/* <Icon name="arrow-long-down"></Icon>30.93% */}
                      </span>
                    </div>
                    <div className="invest-data-history">
                      <div className="title">Últimos 7 dias</div>
                      <span className="amount">
                      {indications.conversion_7_days}%{" "}
                      </span>
                      <span className="change down text-danger">
                        {/* <Icon name="arrow-long-down"></Icon>11.93% */}
                      </span>
                    </div>
                  </div>
                  <div className="invest-data-ck">
                    {/* <BalanceBarChart /> */}
                  </div>
                </div>
              </PreviewAltCard>
            </Col>
            
            <Col xxl="12">
            <PreviewCard>
            <ReactDataTable
                data={indications.data ? indications.data : []}
                columns={dataTableColumns2}
                pagination
                className="nk-tb-list"
                selectableRows
                
                />
            </PreviewCard>  
            </Col>

          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default MinhasIndicacoes;
