import { auth } from "../firebase/filebase";



const getToken = async () => {
    const user = auth.currentUser;
    if (user) {
        const token = await user.getIdToken();
        return token;
    }
    return null;
};



export async function requests(url, method, data, params, headers){
    let token = await getToken();
    var request_headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded"
    }

    if (headers){
        for(key in headers){
            request_headers[key] = headers[key];
        }
    }

    var request_config = {
        method: method,
        headers: request_headers,
        params: params ? params : {},
    }

    if(['post'].indexOf(method.toLowerCase()) > -1){
        let urlEncodedData = "";
        let urlEncodedDataPairs = [];
        let name;
    
        for(name in data) {
            urlEncodedDataPairs.push(encodeURIComponent(name) + '=' + encodeURIComponent(data[name]));
        }
    
        urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');
    
        request_config = {
            ...request_config,
            body: urlEncodedData
        }
    }

    const response = await fetch(url, request_config);

    return response;
}


export const fetchData = async (url, data) => {
    const token = await getToken();
    if (token) {
        const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
        });
        const data = await response.json();
        console.log(data);
    } else {
        console.log('User is not authenticated');
    }
};