import React, { useEffect, useState } from "react";
import Icon from "../../../icon/Icon";
import { UncontrolledDropdown, CardTitle, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import { BarChart, DoubleBar, LineChart } from "../../charts/sales/Charts";
import { Row, Col } from "../../../grid/Grid";
import { Link } from "react-router-dom";

const OrderOverview = (info) => {
  const [orderOverview, setOverview] = useState("");
  useEffect(() => {
    setOverview(info.orders ? info.orders : [])
  }, [info]);
  return (
    <React.Fragment>
      <div className="card-title-group align-start mb-3">
        <CardTitle className="card-title">
          <h6 className="title">Carteira Positivo</h6>
          <p>
            Visualize os valores disponíveis para saque e pendentes de liberação
          </p>
        </CardTitle>
      </div>
      <div className="nk-order-ovwg">
        <Row className="g-4 align-end">

          <Col xxl="4" className="d-flex align-items-center">
            <Row className="g-4">
              <Col xxl="12" sm="6">
                <div className="nk-order-ovwg-data buy">
                  <div className="amount">
                  {info.withdraw_available ? info.withdraw_available : "R$ 0"}
                  </div>
                  <div className="title">
                    <Icon name="check"></Icon> Disponível para Saque
                  </div>
                </div>
              </Col>
              <Col xxl="12" sm="6">
                <div className="nk-order-ovwg-data sell">
                  <div className="amount">
                  {info.withdraw_awaiting ? info.withdraw_awaiting : "R$ 0"}
                  </div>
                  <div className="title">
                    <Icon name="clock"></Icon> Aguardando Liberação
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xxl="8">
            <div className="nk-order-ovwg-ck">
              <DoubleBar state={{
                labels: orderOverview.orders_coluns ? orderOverview.orders_coluns : [],
                dataUnit: "USD",
                datasets: [
                    {
                        label: "Buy Orders",
                        color: "#8feac5",
                        backgroundColor: "#8feac5",
                        barPercentage: 0.8,
                        categoryPercentage: 0.6,
                        data: orderOverview.orders ? orderOverview.orders : [],
                    }
                ],
                }} />
            </div>
          </Col>

        </Row>
      </div>
    </React.Fragment>
  );
};
export default OrderOverview;
