import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Badge, Card } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  TooltipComponent,
  PreviewCard
} from "../components/Component";
import { requests } from "../services/api";
import { toast, ToastContainer } from "react-toastify";
import { CloseButton } from "./components/misc/ReactToastify";
import 'react-toastify/dist/ReactToastify.css';



const LinksCupons = () => {
  const [linkscoupons, setLinkscoupons] = useState([]);

    useEffect(() => {
        fehchLinks();
    }, []);
  
    const fehchLinks = async () => {
        try {
          const response = await requests("https://solutions.bradosmart.com.br/app/api/indication/link-coupon", "GET", {});
          const data = await response.json();
          console.log(data.message);
          setLinkscoupons(data.message);
        } catch (error) {console.log(error)}
    };

    const copyText = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.info("Copiado para a área de transferência", {
                position: "top-center",
                autoClose: true,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: false,
                closeButton: <CloseButton />,
            });
        })
        .catch(err => {});
    }
  return (
    <React.Fragment>
      <Head title="Invest Dashboard" />
      <ToastContainer />
      <Content>
            {linkscoupons.map((item) => 
            <>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                        <BlockTitle page>{item.title}</BlockTitle>
                        <BlockDes className="text-soft">
                            <p>{item.description}</p>
                        </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Card className="">
                        <div className="card-inner-group">
                            {item.codes.map((code) => 
                                <div className="card-inner">
                                    <div className="between-center flex-wrap flex-md-nowrap g-3">
                                        <div className="nk-block-text">
                                        <h6>
                                            {code.description}<Badge color="success" className="ml-0">{code.discount} OFF</Badge><Badge color="dark" className="ml-0">{code.code}</Badge>
                                        </h6>
                                        </div>
                                        <div className="nk-block-actions">
                                        <Button color="primary" onClick={() => copyText(code.code)}>Copiar</Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Card>
                </Block>
            </>
            )}


      </Content>
    </React.Fragment>
  );
};

export default LinksCupons;
