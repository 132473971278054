import React from "react";
import UserAvatar from "../../../user/UserAvatar";
import Icon from "../../../icon/Icon";
import { newUserData } from "./UserData";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, CardTitle, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";

const NewsUsers = ({ indications_data }) => {
  const DropdownTrans = () => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger me-n1">
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu end>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="setting"></Icon>
                <span>Action Settings</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="notify"></Icon>
                <span>Use Notification</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <div className="card-inner-group">
      <div className="card-inner">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">Últimos Indicados</h6>
          </CardTitle>
          <div className="card-tools">
            <Link to={`${process.env.PUBLIC_URL}/user-list-regular`} className="link">
              Ver Todos
            </Link>
          </div>
        </div>
      </div>
      {Array.isArray(indications_data) && indications_data.length > 0 ? (
        indications_data.map((item) => {
          return (
            <div className="card-inner card-inner-md" key={item.id}>
              <div className="user-card">
                <UserAvatar theme={item.theme} text={item.initial}></UserAvatar>
                <div className="user-info">
                  <span className="lead-text">{item.name}</span>
                  <span className="sub-text">Data Cadastro: {item.lastLogin}</span>
                </div>
                <div className="user-action"></div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="card-inner">
          <p>Nenhum usuário encontrado.</p>
        </div>
      )}
    </div>
  );
};

export default NewsUsers;
