import React from "react";
import { CardTitle } from "reactstrap";
import { Icon, TooltipComponent } from "../../../Component";
import { BarChart } from "../../charts/sales/Charts";

const SaleRevenue = (indication) => {
  return (
    <React.Fragment>
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h6 className="title">Novas Indicações</h6>
        </CardTitle>
        <div className="card-tools">
          <TooltipComponent
            icon="help-fill"
            iconClass="card-hint"
            direction="left"
            id="tooltip-1"
            text="Indicações cadastradas"
          />
        </div>
      </div>
      <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
        <div className="nk-sale-data-group flex-md-nowrap g-4">
          <div className="nk-sale-data">
            <span className="amount">
              {indication.last_month ? indication.last_month.new_indications : "0"}{" "}
              <span className="change down text-danger">
                <Icon name="arrow-long-down" />
                {indication.last_month ? indication.last_month.new_indications_rate : "0"}%
              </span>
            </span>
            <span className="sub-title">Nos últimos 30 dias</span>
          </div>
        </div>
        <div className="nk-sales-ck sales-revenue">
          <BarChart sales />
        </div>
      </div>
    </React.Fragment>
  );
};
export default SaleRevenue;
