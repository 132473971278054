export var totalDeposit = {
  labels: ["2 meses atrás", "3 meses atrás", "4 meses atrás", "5 meses atrás", "6 meses atrás"],
  stacked: true,
  datasets: [
    {
      label: "Indicações", // @v2.0
      backgroundColor: [
        "rgb(85, 155, 251)",
        "rgba(85, 155, 251, 0.2)",
        "rgb(85, 155, 251)",
        "rgba(85, 155, 251, 0.2)",
        "rgb(85, 155, 251)",
      ],
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      data: [2, 8, 7, 9, 5],
    },
  ],
};

export var totalWithdraw = {
  labels: ["2 meses atrás", "3 meses atrás", "4 meses atrás", "5 meses atrás", "6 meses atrás"],
  stacked: true,
  datasets: [
    {
      label: "Vendas",
      backgroundColor: [
        "rgb(85, 155, 251)",
        "rgba(85, 155, 251, 0.2)",
        "rgb(85, 155, 251)",
        "rgba(85, 155, 251, 0.2)",
        "rgb(85, 155, 251)",
      ],
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      data: [2, 6, 4, 2, 5],
    },
  ],
};

export var totalBalance = {
  labels: ["2 meses atrás", "3 meses atrás", "4 meses atrás", "5 meses atrás", "6 meses atrás"],
  stacked: true,
  datasets: [
    {
      label: "Taxa",
      backgroundColor: [
        "rgb(85, 155, 251)",
        "rgba(85, 155, 251, 0.2)",
        "rgb(85, 155, 251)",
        "rgba(85, 155, 251, 0.2)",
        "rgb(85, 155, 251)",
      ],
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      data: [5.6, 4.5, 2.9, 4.2, 1.4],
    },
  ],
};

export var planPurchase = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
  ],
  dataUnit: "USD",
  stacked: true,
  datasets: [
    {
      label: "Active User",
      backgroundColor: "rgba(9, 113, 254, 0.5)",
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      data: [
        2000, 10200, 5800, 3500, 5500, 8200, 10690, 3000, 8200, 7800, 9500, 5500, 9200, 7690, 6000, 7200, 1100, 5500,
        7500, 8200, 8690,
      ],
    },
  ],
};

export var planPurchaseSet2 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
  ],
  dataUnit: "USD",
  stacked: true,
  datasets: [
    {
      label: "Active User",
      backgroundColor: "rgba(9, 113, 254, 0.5)",
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      data: [
        6000, 8200, 7800, 9500, 5500, 9200, 9690, 6000, 8200, 7800, 9500, 5500, 9200, 9690, 6000, 8200, 7800, 9500,
        5500, 9200, 9690,
      ],
    },
  ],
};

export var planPurchaseSet3 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
  ],
  dataUnit: "USD",
  stacked: true,
  datasets: [
    {
      label: "Active User",
      backgroundColor: "rgba(9, 113, 254, 0.5)",
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      data: [
        6550, 5200, 5800, 5500, 7500, 10200, 2690, 1000, 9200, 1200, 7500, 1500, 9200, 5590, 8000, 7200, 6800, 7500,
        5500, 9200, 7690,
      ],
    },
  ],
};

export var planPurchaseSet4 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
  ],
  dataUnit: "USD",
  stacked: true,
  datasets: [
    {
      label: "Active User",
      backgroundColor: "rgba(9, 113, 254, 0.5)",
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      data: [
        5000, 2200, 5800, 3500, 2500, 4200, 8690, 9000, 2200, 1800, 8500, 7500, 9200, 8690, 7000, 8200, 1800, 1500,
        1500, 7200, 8690,
      ],
    },
  ],
};
