import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  TooltipComponent,
  PreviewCard
} from "../components/Component";
import { requests } from "../services/api";
import MaskedInput from 'react-maskedinput';
import { toast, ToastContainer } from "react-toastify";
import { CloseButton } from "./components/misc/ReactToastify";
import 'react-toastify/dist/ReactToastify.css';
import UserAvatar from "../components/user/UserAvatar";



const CadastrarIndicacao = () => {
    const [sm, updateSm] = useState(false);
    const [indications, setIndications] = useState([]);

    const fetchIndications = async () => {
        try {
          const response = await requests("https://solutions.bradosmart.com.br/app/api/indication/manager?limit=true", "GET", {});
          const data = await response.json();
          console.log(data);
          setIndications(data.message.data); // Certifique-se de que data.message contém os dados esperados
        } catch (error) {
          console.error("Erro ao buscar dados de indicações:", error);
        }
    };
    
    const SendIndication = async () => {
        console.log("TESTE");
        var data = {};
        var form = document.querySelectorAll('[data-form]');
        form.forEach(function(e){
            console.log(e);
            var key = e.getAttribute('data-form');
            var val = e.value;
            data[key] = val;
            // console.log(this.getAttribute('data-form'));
        })

        var response = await requests("https://solutions.bradosmart.com.br/app/api/indication/manager", "POST", data)
        if(response.response){
            const infoToast = () => {
                toast.success(response.message, {
                  position: "bottom-right",
                  autoClose: true,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: false,
                  closeButton: '<span className="btn-trigger toast-close-button" role="button"><Icon name="cross"></Icon></span>',
                });
              };
            infoToast()
        }else{
            const infoToast = () => {
                toast.error(response.message, {
                  position: "bottom-right",
                  autoClose: true,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: false,
                  closeButton: <CloseButton />,
                });
              };
            infoToast();
        }
    };  
                  

    useEffect(() => {
        const verifyAccess = async () => {
            var response = await requests("https://solutions.bradosmart.com.br/app/api/indication/manager", "GET", {});
            console.log(response.text);
        }
        verifyAccess();
        fetchIndications();
    }, []);

  return (
    <React.Fragment>
      <Head title="Invest Dashboard" />
      <ToastContainer />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Cadastrar Indicação</BlockTitle>
              <BlockDes className="text-soft">
                <p>Realize o cadastro de uma nova indicação manualmente</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Row className="g-gs">
            
            <Col md="4">
              <PreviewCard className="h-100">
                <div className="card-head">
                  <h5 className="card-title">Informações do Indicado</h5>
                </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="name">
                      Nome Completo
                    </label>
                    <div className="form-control-wrap">
                      <input className="form-control" type="text" data-form="name" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="email">
                      Endereço de E-mail
                    </label>
                    <div className="form-control-wrap">
                      <input className="form-control" type="text" data-form="email" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="phone">
                      Número de Telefone
                    </label>
                    <div className="form-control-wrap">
                        <MaskedInput className="form-control" mask="(11) 11111-1111" type="text" data-form="phone"/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Informações sobre o indicado:</label>
                    <ul className="custom-control-group g-3 align-center">
                      <li>
                        <div className="custom-control custom-control-sm custom-checkbox">
                          <input type="checkbox" className="custom-control-input custom-control-sm" id="business_owner" data-form="business_owner" />
                          <label className="custom-control-label" htmlFor="business_owner">
                            Dono de Empresa
                          </label>
                        </div>
                      </li>
                    </ul>
                    <ul className="custom-control-group g-3 align-center">
                      <li>
                        <div className="custom-control custom-control-sm custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="personal" data-form="personal" />
                          <label className="custom-control-label" htmlFor="personal">
                            Parente ou Amigo Pessoal
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="form-group">
                    <Button onClick={() => SendIndication()} color="primary" size="lg">
                      Cadastrar Indicação
                    </Button>
                  </div>
              </PreviewCard>
            </Col>
            <Col md="4">
              <Card className=" card-full">
                {indications.map((item) =>   
                    <div className="card-inner card-inner-md" key={item.id}>
                        <div className="user-card">
                            <UserAvatar theme={item.theme} text={item.initial}></UserAvatar>
                            <div className="user-info">
                                <span className="lead-text">{item.name}</span>
                                <span className="sub-text">Data Cadastro: {item.lastLogin}</span>
                            </div>
                            <div className="user-action">
                            </div>
                        </div>
                    </div>
                )}
              </Card>
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default CadastrarIndicacao;
