import React from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Block, BlockDes, BlockHead, BlockHeadContent, BlockTitle, BlockBetween, PreviewCard } from "../components/Component";
import jornadapositivo from "../images/jornadapositivo/jornadapositivo.jpg";
import Accordion from "../components/partials/accordian/Preview";

const JornadaPositivo = ({ ...props }) => {
  
  const paragraphStyle = (index) => ({
    fontSize: '1em',
    lineHeight: '1.75em',
    borderTop: index === 0 ? 'none' : '3px solid',
    borderImage: 'linear-gradient(to right, #005be2 0%, #fd7104 100%)',
    borderImageSlice: '1',
    borderWidth: '3px',
    margin: '0',
    padding: '40px',
    counterIncrement: 'section',
    position: 'relative',
    color: '#34435E',
    borderRight: index % 2 === 0 ? 'none' : '3px solid',
    paddingLeft: index % 2 === 0 ? '40px' : '0',
    borderLeft: index % 2 === 0 ? '3px solid' : 'none',
    paddingRight: index % 2 === 0 ? '0' : '40px',
    borderTopRightRadius: index === 0 ? '0' : undefined,
    borderTopLeftRadius: index === 0 ? '0' : undefined,
    borderBottomRightRadius: index === 4 ? '0' : undefined,
    borderBottomLeftRadius: index === 4 ? '0' : undefined,
    textAlign: index % 2 === 0 ? 'right' : 'left',
  });
  
  return (
    <React.Fragment>
      <Head title="Terms &amp; Conditions" />

      <Content>
        <div className="content-page  m-auto">
          <Block>
            <PreviewCard className="">
              <div className="entry">

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', textAlign: 'center'}}>
                  <h2>Jornada Positivo</h2>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px', textAlign: 'center'}}>
                  <h5>Trilha para restauração da sua reputação financeira</h5>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={jornadapositivo} alt="Jornada Positivo" />
                </div>

                <div className="styled-paragraphs">
                  <main style={{ minWidth: '300px', maxWidth: '500px', margin: 'auto', padding: '0 4em'}}>
                    <p style={paragraphStyle(0)}><h4><strong>Start Positivo</strong><br /> Remova legalmente os apontamentos do seu nome ou empresa no serviços de proteção ao crédito</h4></p>
                    <p style={paragraphStyle(1)}><h4><strong>Escudo Positivo</strong><br /> Proteja seu nome ou empresa de outros apontamentos mesmo com novas dívidas</h4></p>
                    <p style={paragraphStyle(2)}><h4><strong>Guardião Positivo</strong><br /> Garantia extendida da proteção contra novos apontamentos por 1, 2, 3 ou 5 anos</h4></p>
                    <p style={paragraphStyle(3)}><h4><strong>Guia Positivo</strong><br /> Manual com o passo a passo de como aumentar o score e rating bancário seu ou da sua empresa</h4></p>
                    <p style={paragraphStyle(4)}><h4><strong>Final da Trilha</strong><br /> Reputação financeira restaurada e possibilidade de acesso ao crédito no mercado</h4></p>
                    <p style={{ ...paragraphStyle(5), textAlign: 'right'}}><a href="/demo9/loja-positivo" class="btn btn-xl btn-primary">Quero seguir a Trilha Positivo</a><p/></p>
                  </main>
                </div>

                <div style={{marginTop: '60px', marginBottom: '50px', textAlign: 'center'}}>
                  <h4>Perguntas frequentes</h4>
                  <Accordion/>
                </div>

              </div>
            </PreviewCard>
          </Block>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default JornadaPositivo;