import { Alert } from "reactstrap";
import { db, auth } from "../firebase/filebase";
import { 
  collection,
  doc,
  addDoc,
  getDoc,
  setDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";

export async function fetchUserProductsDetails() {
  const combinedResults = [];

  try {

    // Verifique se auth.uid está definido
    if (!auth.currentUser.uid) {
      throw new Error("auth.uid não está definido");
    }

    // Passo 1: Buscar IDs dos produtos do usuário
    const collectionRef = collection(db, "usuarios", auth.currentUser.uid, "produtos");
    const querySnapshot = await getDocs(collectionRef);
    
    for (const userDoc of querySnapshot.docs) {
      try {
        
        const userProductData = userDoc.data();
        const userProductId = userProductData.id_produto;
        var newUserProduct = {};

        let data_compra = userProductData["data_compra"].toDate();
        let visto_ultimo = userProductData["visto_ultimo"].toDate();
        
        newUserProduct["ultimo_progresso"] = userProductData["ultimo_progresso"] ?? 0;
        newUserProduct["data_compra"] = `${String(data_compra.getDate()).padStart(2, '0')}/${String(data_compra.getMonth() + 1).padStart(2, '0')}/${data_compra.getFullYear()}`;
        newUserProduct["visto_ultimo"] = `${String(visto_ultimo.getDate()).padStart(2, '0')}/${String(visto_ultimo.getMonth() + 1).padStart(2, '0')}/${visto_ultimo.getFullYear()}`;
        newUserProduct["id_produto"] = userProductData["id_produto"];

        // Passo 2: Buscar detalhes dos produtos usando os IDs
        const productDocRef = doc(db, "produtos", userProductId);
        const productDoc = await getDoc(productDocRef);

        if (productDoc.exists()) {
          const productData = productDoc.data();
          console.log(productData);
          const combinedData = {
            id: userProductId,
            ...newUserProduct, // Dados da coleção de produtos do usuário
            ...productData // Dados da coleção principal de produtos
          };
          combinedResults.push(combinedData);
        } else {
          console.log(`Produto com ID ${userProductId} não encontrado!`);
        }
      } catch (error) {
        console.error(`Erro ao processar o produto com ID ${userDoc.id}: `, error);
      }
    }

    if (combinedResults.length === 0) {
      console.log("Nenhum produto encontrado!");
    } else {
      console.log("Dados combinados dos produtos:", combinedResults);
      return combinedResults;
    }

  } catch (error) {
    console.error("Erro ao buscar detalhes dos produtos do usuário: ", error);
    return [];
  }
}