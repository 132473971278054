import React from "react";
import Icon from "../../../icon/Icon";
import Button from "../../../button/Button";
import { UncontrolledDropdown, CardTitle, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";

const ActionCenter = () => {
  return (
    <React.Fragment>
      {" "}
      <div className="card-inner-group">
        <div className="card-inner card-inner-md">
          <div className="card-title-group" style={{ marginTop: '10px', marginBottom: '10px' }}>
            <CardTitle>
              <h6 className="title">Ações Importantes</h6>
            </CardTitle>
          </div>
        </div>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
              <Icon name="wallet-out"></Icon>
              <div className="title">Requisição de Saque</div>
              <p>
                Faça uma requisição para sacar da sua conta os <strong>valores disponíveis para saque</strong>
              </p>
              <p>
                As requisições de saque podem demorar até <strong>72 horas</strong> para serem processadas
              </p>
            </div>
            <Button className="btn-icon btn-trigger me-n2">
              <Icon name="forward-ios"></Icon>
            </Button>
          </div>
        </div>
        
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
              <Icon name="help-fill"></Icon>
              <div className="title">Mensagem ao Suporte</div>
              <p>
                Envie uma mensagem para o nosso <strong>suporte</strong>{" "}
              </p>
            </div>
            <Button className="btn-icon btn-trigger me-n2">
              <Icon name="forward-ios"></Icon>
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ActionCenter;
