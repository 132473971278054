import Router from "./route/Index";
import ThemeProvider from "./layout/provider/Theme";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase/filebase";
import { onAuthStateChanged } from "firebase/auth";

import Logo from "./images/logo.png";
import LogoDark from "./images/logo-dark.png";
import Head from "./layout/head/Head";
import AuthFooter from "./pages/auth/AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Icon,
  PreviewCard,
} from "./components/Component";
import { Spinner, Alert } from "reactstrap";
import { Link } from "react-router-dom";


const App = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLogged(true);
      } else {
        setIsLogged(false);
        navigate("/demo9/auth-login");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  if (loading) {
    return <>
    <Head title="Loading" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>

        <PreviewCard className="" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Carregando</BlockTitle>
              <BlockDes>
                <div className="form-note-s2 text-center pt-4">
                    <Spinner color="dark" />
                    {/* <p>Aguarde...</p> */}
                </div>
              </BlockDes>
            </BlockContent>
          </BlockHead>

          
        </PreviewCard>
      </Block>
      
  </>;
  }

  return (
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  );
};

export default App;

