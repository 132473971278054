import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { Card} from "reactstrap";
import Head from "../../../layout/head/Head";
import DatePicker from "react-datepicker";
import { Modal, ModalBody } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  Button,
  RSelect
} from "../../../components/Component";
import { countryOptions, listaBancosBrasileiros, tipoContaBanco, tipoChavePix, userData } from "./UserData";
import { getDateStructured } from "../../../utils/Utils";
import UserProfileAside from "./UserProfileAside";

const UserProfileRegularPage = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView , setMobileView] = useState(false);
  
  const [modalTab, setModalTab] = useState("1");
  const [userInfo, setUserInfo] = useState(userData[0]);
  const [formData, setFormData] = useState({
    nome: "Rodrigo Boss",
    cpf: "484.554.654-10",
    email: "rodrigo@boss.com.br",
    telefone: "11-98056-7474",
    data_nascimento: "10/08/1980",
    endereco: "Rua Estela 515",
    cep: "02233-000",
    cidade: "São Paulo",
    estado: "São Paulo",
    pagamento_banco: "260 - Nu Pagamentos S.A",
    pagamento_tipo_conta: "Conta Corrente",
    pagamento_agencia: "1124",
    pagamento_conta: "044587245",
    pagamento_tipo_pix: "Telefone Celular",
    pagamento_chave_pix: "11985454465",
  });

  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm1 = () => {
    let submitData = {
      ...formData,
    };
    setUserInfo(submitData);
    setModal1(false);
  };

  const submitForm2 = () => {
    let submitData = {
      ...formData,
    };
    setUserInfo(submitData);
    setModal2(false);
  };

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  
  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <Content>
        <Card className="">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <UserProfileAside updateSm={updateSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Perfil Positivo</BlockTitle>
                    <BlockDes>
                      <p>Informações sobre seu perfil registradas no nosso sistema.</p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent className="aign-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              <Block>
                <div className="nk-data data-list">
                  <div className="data-head">
                    <h6 className="overline-title">Informações Pessoais e Localidade</h6>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">Nome Completo</span>
                      <span className="data-value text-soft">{formData.nome}</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable">
                        <Icon name="lock-alt"></Icon>
                      </span>
                    </div>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">CPF</span>
                      <span className="data-value text-soft">{formData.cpf}</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable">
                        <Icon name="lock-alt"></Icon>
                      </span>
                    </div>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">Data de Nascimento</span>
                      <span className="data-value  text-soft">{formData.data_nascimento}</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable">
                        <Icon name="lock-alt"></Icon>
                      </span>
                    </div>
                  </div>
                  <div className="data-item" onClick={() => setModal1(true)}>
                    <div className="data-col">
                      <span className="data-label">Email</span>
                      <span className="data-value">{formData.email}</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div>
                  <div className="data-item" onClick={() => setModal1(true)}>
                    <div className="data-col">
                      <span className="data-label">Telefone</span>
                      <span className="data-value">{formData.telefone}</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div>
                  <div className="data-item" onClick={() => setModal1(true)}>
                    <div className="data-col">
                      <span className="data-label">Endereço</span>
                      <span className="data-value">
                        {formData.endereco},
                        <br />
                        {formData.cidade}, {formData.estado}
                      </span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div>
                </div>
              </Block>

              <Block>
                <div className="nk-data data-list">
                  <div className="data-head">
                    <h6 className="overline-title">Informações Bancárias</h6>
                  </div>
                  <div className="data-item" onClick={() => setModal2(true)}>
                    <div className="data-col">
                      <span className="data-label">Dados da Conta</span>
                      <span className="data-value">
                        {formData.pagamento_banco}
                        <br />
                        {formData.pagamento_tipo_conta}
                        <br />
                        Agência: {formData.pagamento_agencia}
                        <br />
                        Conta: {formData.pagamento_conta}
                      </span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div>
                  <div className="data-item" onClick={() => setModal2(true)}>
                    <div className="data-col">
                      <span className="data-label">Dados do PIX</span>
                      <span className="data-value">
                        Tipo da Chave: {formData.pagamento_tipo_pix}
                        <br />
                        Chave: {formData.pagamento_chave_pix}
                      </span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div>
                </div>
              </Block>

              <Modal isOpen={modal1} className="modal-dialog-centered" size="lg" toggle={() => setModal1(false)}>
                  <a
                    href="#dropdownitem"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setModal1(false);
                    }}
                    className="close"
                  >
                    <Icon name="cross-sm"></Icon>
                  </a>
                <ModalBody>
                  <div className="p-2">
                    <h5 className="title">Atualizar Informações</h5>
                    <ul className="nk-nav nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className={`nav-link ${modalTab === "1" && "active"}`}
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModalTab("1");
                          }}
                          href="#personal"
                        >
                          Dados Pessoais
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${modalTab === "2" && "active"}`}
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModalTab("2");
                          }}
                          href="#address"
                        >
                          Localidade
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="personal">
                        <Row className="gy-4">
                          <Col md="6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="email">
                                Email
                              </label>
                              <input
                                type="text"
                                id="email"
                                className="form-control"
                                name="email"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.email}
                                placeholder="Digite seu e-mail"
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="telefone">
                                Telefone
                              </label>
                              <input
                                type="text"
                                id="telefone"
                                className="form-control"
                                name="telefone"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.telefone}
                                placeholder="Digite seu telefone com DDD"
                              />
                            </div>
                          </Col>
                          <Col size="12">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                              <li>
                                <Button
                                  color="primary"
                                  size="lg"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    submitForm1();
                                  }}
                                >
                                  Atualizar Dados
                                </Button>
                              </li>
                              <li>
                                <a
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setModal1(false);
                                  }}
                                  className="link link-light"
                                >
                                  Cancelar
                                </a>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                      <div className={`tab-pane ${modalTab === "2" ? "active" : ""}`} id="cep">
                        <Row className="gy-4">
                          <Col md="3">
                            <div className="form-group">
                              <label className="form-label" htmlFor="cep">
                                CEP
                              </label>
                              <input
                                type="text"
                                id="cep"
                                name="cep"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.cep}
                                className="form-control"
                                placeholder="Digite seu CEP"
                              />
                            </div>
                          </Col>
                          <Col md="9">
                            <div className="form-group">
                              <label className="form-label" htmlFor="endereco">
                                Endereço
                              </label>
                              <input
                                type="text"
                                id="endereco"
                                name="endereco"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.endereco}
                                className="form-control"
                                placeholder="Digite seu endereço completo"
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="cidade">
                                Cidade
                              </label>
                              <input
                                type="text"
                                id="cidade"
                                name="cidade"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.cidade}
                                className="form-control"
                                placeholder="Digite sua cidade"
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="estado">
                                Estado
                              </label>
                              <RSelect
                                options={countryOptions}
                                placeholder="Selecione um Estado"
                                defaultValue={[
                                  {
                                    value: formData.estado,
                                    label: formData.estado,
                                  },
                                ]}
                                onChange={(e) => setFormData({ ...formData, estado: e.value })}
                              />
                            </div>
                          </Col>
                          <Col size="12">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                              <li>
                                <Button color="primary" size="lg" onClick={() => submitForm1()}>
                                  Atualizar Endereço
                                </Button>
                              </li>
                              <li>
                                <a
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setModal1(false);
                                  }}
                                  className="link link-light"
                                >
                                  Cancelar
                                </a>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>

              <Modal isOpen={modal2} className="modal-dialog-centered" size="lg" toggle={() => setModal2(false)}>
                  <a
                    href="#dropdownitem"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setModal2(false);
                    }}
                    className="close"
                  >
                    <Icon name="cross-sm"></Icon>
                  </a>
                <ModalBody>
                  <div className="p-2">
                    <h5 className="title">Atualizar Informações</h5>
                    <ul className="nk-nav nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className={`nav-link ${modalTab === "1" && "active"}`}
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModalTab("1");
                          }}
                          href="#personal"
                        >
                          Dados da Conta
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${modalTab === "2" && "active"}`}
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModalTab("2");
                          }}
                          href="#address"
                        >
                          Dados do PIX
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      
                    <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="address">
                        <Row className="gy-4">

                        <Col md="6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="banco">
                                Instituição Financeira
                              </label>
                              <RSelect
                                options={listaBancosBrasileiros}
                                placeholder="Selecione um Banco"
                                defaultValue={[
                                  {
                                    value: formData.pagamento_banco,
                                    label: formData.pagamento_banco,
                                  },
                                ]}
                                onChange={(e) => setFormData({ ...formData, pagamento_banco: e.value })}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="tipo-conta">
                                Tipo de Conta
                              </label>
                              <RSelect
                                options={tipoContaBanco}
                                placeholder="Selecione um Tipo"
                                defaultValue={[
                                  {
                                    value: formData.pagamento_tipo_conta,
                                    label: formData.pagamento_tipo_conta,
                                  },
                                ]}
                                onChange={(e) => setFormData({ ...formData, pagamento_tipo_conta: e.value })}
                              />
                            </div>
                          </Col>

                          <Col md="3">
                            <div className="form-group">
                              <label className="form-label" htmlFor="numero-agencia">
                                Agência
                              </label>
                              <input
                                type="number"
                                id="numero-agencia"
                                name="numero-agencia"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.pagamento_agencia}
                                className="form-control"
                                placeholder="Digite sua agência"
                              />
                            </div>
                          </Col>
                          <Col md="9">
                            <div className="form-group">
                              <label className="form-label" htmlFor="numero-conta">
                                Conta
                              </label>
                              <input
                                type="number"
                                id="numero-conta"
                                name="numero-conta"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.pagamento_conta}
                                className="form-control"
                                placeholder="Digite sua conta"
                              />
                            </div>
                          </Col>

                          <Col size="12">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                              <li>
                                <Button color="primary" size="lg" onClick={() => submitForm2()}>
                                  Atualizar Conta
                                </Button>
                              </li>
                              <li>
                                <a
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setModal2(false);
                                  }}
                                  className="link link-light"
                                >
                                  Cancelar
                                </a>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                      
                      <div className={`tab-pane ${modalTab === "2" ? "active" : ""}`} id="personal">
                        <Row className="gy-4">
                          
                        <Col md="3">
                            <div className="form-group">
                              <label className="form-label" htmlFor="tipo-pix">
                                Tipo de Chave
                              </label>
                              <RSelect
                                options={tipoChavePix}
                                placeholder="Selecione um Tipo"
                                defaultValue={[
                                  {
                                    value: formData.pagamento_tipo_pix,
                                    label: formData.pagamento_tipo_pix,
                                  },
                                ]}
                                onChange={(e) => setFormData({ ...formData, pagamento_tipo_pix: e.value })}
                              />
                            </div>
                          </Col>

                          <Col md="9">
                            <div className="form-group">
                              <label className="form-label" htmlFor="chave-pix">
                                Chave
                              </label>
                              <input
                                type="text"
                                id="chave-pix"
                                className="form-control"
                                name="chave-pix"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.pagamento_chave_pix}
                                placeholder="Digite seu telefone com DDD"
                              />
                            </div>
                          </Col>
                          <Col size="12">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                              <li>
                                <Button
                                  color="primary"
                                  size="lg"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    submitForm2();
                                  }}
                                >
                                  Atualizar PIX
                                </Button>
                              </li>
                              <li>
                                <a
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setModal2(false);
                                  }}
                                  className="link link-light"
                                >
                                  Cancelar
                                </a>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                      
                    </div>
                  </div>
                </ModalBody>
              </Modal>                   

            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default UserProfileRegularPage;
