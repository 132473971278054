import StartPositivo from "../images/produtos/start-positivo.jpg";
import EscudoPositivo from "../images/produtos/escudo-positivo.jpg";
import GuardiaoPositivo from "../images/produtos/guardiao-positivo.jpg";
import GuiaPositivo from "../images/produtos/guia-positivo.jpg";
import JornadaPositivo from "../images/produtos/jornada-positivo.jpg";

import ProductLGA from "../images/product/lg-a.jpg";
import ProductLGB from "../images/product/lg-b.jpg";
import ProductLGC from "../images/product/lg-c.jpg";
import ProductLGD from "../images/product/lg-d.jpg";
import ProductLGE from "../images/product/lg-e.jpg";
import ProductLGF from "../images/product/lg-f.jpg";
import ProductLGG from "../images/product/lg-g.jpg";
import ProductLGH from "../images/product/lg-h.jpg";

export const productData = [
  {
    id: 1,
    name: "Pink Fitness Tracker",
    img: StartPositivo,
    sku: "UY3749",
    price: "99.49",
    stock: 49,
    category: [
      { label: "Fitbit", value: "Fitbit" },
      { label: "Tracker", value: "Tracker" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 2,
    name: "Purple Smartwatch",
    img: StartPositivo,
    sku: "UY3750",
    price: "89.49",
    stock: 103,
    category: [
      { label: "Fitbit", value: "Fitbit" },
      { label: "Gadgets", value: "Gadgets" },
      { label: "Tracker", value: "Tracker" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 3,
    name: "Black Mi Band Smartwatch",
    img: StartPositivo,
    sku: "UY3751",
    price: "299.49",
    stock: 68,
    category: [
      { label: "Smartwatch", value: "Smartwatch" },
      { label: "Tracker", value: "Tracker" },
    ],
    fav: true,
    check: false,
  },
  {
    id: 4,
    name: "Black Headphones",
    img: StartPositivo,
    sku: "UY3752",
    price: "99.49",
    stock: 77,
    category: [
      { label: "Headphones", value: "Headphones" },
      { label: "Gadgets", value: "Gadgets" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 5,
    name: "Iphone 7 Headphones",
    img: StartPositivo,
    sku: "UY3753",
    price: "199.49",
    stock: 81,
    category: [
      { label: "Headphones", value: "Headphones" },
      { label: "Gadgets", value: "Gadgets" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 6,
    name: "Purple Blue Gradient iPhone Case",
    img: StartPositivo,
    sku: "UY3754",
    price: "29.49",
    stock: 28,
    category: [
      { label: "Case", value: "Case" },
      { label: "Gadgets", value: "Gadgets" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 7,
    name: "Plug In Speaker",
    img: StartPositivo,
    sku: "UY3755",
    price: "19.49",
    stock: 37,
    category: [
      { label: "Case", value: "Case" },
      { label: "Gadgets", value: "Gadgets" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 8,
    name: "Wireless Waterproof Speaker",
    img: StartPositivo,
    sku: "UY3756",
    price: "59.49",
    stock: 37,
    category: [
      { label: "Speaker", value: "Speaker" },
      { label: "Gadgets", value: "Gadgets" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 9,
    name: "AliExpress Fitness Trackers",
    img: StartPositivo,
    sku: "UY3758",
    price: "35.49",
    stock: 145,
    category: [
      { label: "Fitbit", value: "Fitbit" },
      { label: "Tracker", value: "Tracker" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 10,
    name: "Pool Party Drink Holder",
    img: StartPositivo,
    sku: "UY3757",
    price: "9.49",
    stock: 73,
    category: [
      { label: "Men", value: "Men" },
      { label: "Holder", value: "Holder" },
    ],
    fav: false,
    check: false,
  },
];

export const productCardData = [
  {
    id: 0,
    img: StartPositivo,
    novo: true,
    adquirido: true,
    slider: [
      { id: 0, img: StartPositivo }
    ],
    title: "Start POSITIVO",
    desc: "Remova legalmente os apontamentos do seu nome ou empresa no serviços de proteção ao crédito",
    newPrice: 1299,
  },
  {
    id: 1,
    img: EscudoPositivo,
    novo: false,
    adquirido: false,
    slider: [
      { id: 0, img: EscudoPositivo },
    ],
    title: "Escudo POSITIVO",
    desc: "Proteja seu nome ou empresa de outros apontamentos mesmo com novas dívidas",
    newPrice: 1299,
  },
  {
    id: 2,
    img: GuardiaoPositivo,
    novo: false,
    adquirido: false,
    slider: [
      { id: 0, img: GuardiaoPositivo },
    ],
    title: "Guardião POSITIVO",
    desc: "Garantia extendida da proteção contra novos apontamentos nos orgãos de proteção ao crédito por 1, 2, 3 ou 5 anos",
    newPrice: 799,
  },
  {
    id: 3,
    img: GuiaPositivo,
    novo: false,
    adquirido: false,
    slider: [
      { id: 0, img: GuiaPositivo },
    ],
    title: "Guia POSITIVO",
    desc: "Manual com o passo a passo de como aumentar o score e rating bancário seu ou da sua empresa",
    newPrice: 299,
  },
  {
    id: 4,
    img: JornadaPositivo,
    novo: false,
    adquirido: false,
    slider: [
      { id: 0, img: JornadaPositivo },
    ],
    title: "Jornada POSITIVO",
    desc: "Pacote completo com tudo que você precisa para restaurar a sua reputação financeira",
    newPrice: 1999,
  }
];

export const categoryOptions = [
  {
    value: "Gadget",
    label: "Gadget",
  },
  {
    value: "Electronics",
    label: "Electronics",
  },
  {
    label: "Watch",
    value: "Watch",
  },
  {
    label: "Tracker",
    value: "Tracker",
  },
  {
    label: "Fitbit",
    value: "Fitbit",
  },
  {
    label: "Men",
    value: "Men",
  },
  {
    label: "Holder",
    value: "Holder",
  },
  {
    label: "Speaker",
    value: "Speaker",
  },
  {
    label: "Headphones",
    value: "Headphones",
  },
  {
    label: "Bundle",
    value: "Bundle",
  },
];
