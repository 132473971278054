import React, { useState, useEffect, useRef, useContext } from "react";
import ProductVideo from "../images/product/video-a.jpg";
import ModalVideo from "react-modal-video";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import Slider from "react-slick";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
  Icon,
  Block,
} from "../components/Component";
import { Badge, Card } from "reactstrap";
import { ProductContext } from "./ProductContext";
import { Link, useParams } from "react-router-dom";
import { SlickArrowLeft, SlickArrowRight } from "../components/partials/slick/SlickComponents";

const sliderSettings = {
  className: "slider-init row",
  slidesToShow: 2,
  centerMode: false,
  slidesToScroll: 1,
  infinite: false,
  prevArrow: <SlickArrowLeft />,
  nextArrow: <SlickArrowRight />,
  responsive: [
    { breakpoint: 3000, settings: { slidesToShow: 4 } },
    { breakpoint: 1540, settings: { slidesToShow: 3 } },
    { breakpoint: 992, settings: { slidesToShow: 2 } },
    { breakpoint: 576, settings: { slidesToShow: 1 } },
  ],
};

const sliderSettingsDefault = {
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  slide: null,
  responsive: [
    { breakpoint: 1539, settings: { slidesToShow: 3 } },
    { breakpoint: 768, settings: { slidesToShow: 2 } },
    { breakpoint: 420, settings: { slidesToShow: 1 } },
  ],
  arrows: false,
  swipeToSlide: true,
  focusOnSelect: true,
  className: "slider-init slider-nav",
};

const ProductDetails = ({ match }) => {
  const { contextData } = useContext(ProductContext);

  const [data] = contextData;

  const [sliderData, setSliderData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState({});
  const [colorSector, setColorSelector] = useState(1);
  const [sizeSelector, setSizeSelector] = useState(1);
  const [counter, setCounter] = useState(1);
  const [videoOpen, setVideoOpen] = useState(false);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  // increases quantity number
  const increaseCounter = () => {
    setCounter((prevState) => prevState + 1);
  };

  // decreases quantity number
  const decreaseCounter = () => {
    if (counter !== 0) {
      setCounter((prevState) => prevState - 1);
    }
  };

  // changes slides
  const slideChange = (index) => {
    var product = sliderData.slider.find((item) => item.id === index);
    setCurrentSlide(product);
  };

  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  let {productId} = useParams();
  // grabs the id of the url and loads the corresponding data
  useEffect(() => {
    const id = productId;
    if (id !== undefined || null || "") {
      let product = data.find((item) => item.id === Number(id));
      if (product) {
        setSliderData(product);
        setCurrentSlide(product.slider[0]);
      }
    } else {
      setSliderData(data[0]);
      setCurrentSlide(data[0].slider[0]);
    }
  }, [productId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <Head title="Product Detail"></Head>
      {sliderData && sliderData.slider && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween className="g-3">
              <BlockHeadContent>
                <BlockTitle page>Detalhes do Produto</BlockTitle>
                <BlockDes className="text-soft">
                  <p>Saiba mais sobre o produto selecionado</p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <Link to={`${process.env.PUBLIC_URL}/product-card`}>
                  <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                    <Icon name="arrow-left"></Icon>
                    <span>Voltar</span>
                  </Button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/product-card`}>
                  <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                    <Icon name="arrow-left"></Icon>
                  </Button>
                </Link>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="">
              <div className="card-inner">
                <Row className="pb-5">
                  <Col lg={6}>
                    <div className="product-gallery me-xl-1 me-xxl-5">
                      <Slider
                        asNavFor={nav2}
                        ref={slider1}
                        arrows={false}
                        fade={true}
                        slidesToShow={1}
                        slidesToScroll={1}
                        initialSlide={currentSlide.id}
                        className="slider-init"
                        prevArrow
                      >
                        <div className="slider-item rounded" key={currentSlide.id}>
                          <img src={currentSlide.img} className="w-100" alt="" />
                        </div>
                      </Slider>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="product-info mt-5 me-xxl-5">
                      <h4 className="product-price text-primary">
                          <small className="text-muted fs-14px">
                            A partir de<br />
                          </small>
                          R${sliderData.newPrice}{" "}
                      </h4>
                      <h2 className="product-title">{sliderData.title}</h2>
                      <div className="product-excrept text-soft">
                        <p className="lead">
                          {sliderData.desc}
                        </p>
                      </div>                   
                      <div className="product-meta">
                        <h6 className="title">Valor da sua dívida</h6>
                        <ul className="custom-control-group">
                          <li>
                            <div className="custom-control custom-radio custom-control-pro no-control">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="sizeCheck"
                                id="sizeCheck1"
                                onChange={() => setSizeSelector(1)}
                                checked={sizeSelector === 1 ? true : false}
                              />
                              <label className="custom-control-label" htmlFor="sizeCheck1">
                                Até R$R$1.000
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-radio custom-control-pro no-control">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="sizeCheck"
                                id="sizeCheck2"
                                onChange={() => setSizeSelector(2)}
                                checked={sizeSelector === 2 ? true : false}
                              />
                              <label className="custom-control-label" htmlFor="sizeCheck2">
                                Até R$10.000
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-radio custom-control-pro no-control">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="sizeCheck"
                                id="sizeCheck3"
                                onChange={() => setSizeSelector(3)}
                                checked={sizeSelector === 3 ? true : false}
                              />
                              <label className="custom-control-label" htmlFor="sizeCheck3">
                                Acima de R$10.000
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="product-meta">
                        <ul className="d-flex flex-wrap ailgn-center g-2 pt-1">
                          <li className="w-140px">
                            <Button color="primary">Comprar</Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Block>

          <Block size="lg">
          <BlockHead size="sm">
            <BlockBetween className="g-3">
              <BlockHeadContent>
                <BlockTitle page>Produtos Relacionados</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
            <Slider {...sliderSettings}>
              {data.map((item) => {
                return (
                  <Col key={item.id}>
                    <Card className="product-card me-3 ms-3">
                      <div className="product-thumb">
                        <Link to={`${process.env.PUBLIC_URL}/product-details/${item.id}`}>
                          <img className="card-img-top" src={item.img} alt=""/>
                        </Link>
                        <ul className="product-badges">
                          {item.novo && (
                            <li>
                              <Badge color="success">Novo</Badge>
                            </li>
                          )}
                          {item.adquirido && (
                            <li>
                              <Badge color="danger">Adquirido</Badge>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="card-inner text-center">
                        <ul className="product-tags">
                          <li>
                            <a href="#product" onClick={(ev) => ev.preventDefault()}>
                              {item.name}
                            </a>
                          </li>
                        </ul>
                        <h5 className="product-title">
                          <Link to={`${process.env.PUBLIC_URL}/product-details/${item.id}`}>{item.title}</Link>
                        </h5>
                        <div className="product-price text-primary h5">
                          <small className="text-muted fs-13px">A partir de <br /></small>
                          R${item.newPrice}
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Slider>
          </Block>
        </Content>
      )}
    </React.Fragment>
  );
};

export default ProductDetails;
