import React, { useContext, useState, useEffect } from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import Dropzone from "react-dropzone";
import ProductH from "../images/product/h.png";
import ProductLGB from "../images/product/lg-b.jpg";
import ProductLGC from "../images/product/lg-c.jpg";
import ProductLGD from "../images/product/lg-d.jpg";
import ProductLGE from "../images/product/lg-e.jpg";
import ProductLGF from "../images/product/lg-f.jpg";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import {
  BlockHead,
  BlockDes,
  BlockTitle,
  BlockBetween,
  BlockHeadContent,
  Icon,
  Button,
  Block,
  Row,
  Col,
  PaginationComponent,
} from "../components/Component";
import { useForm } from "react-hook-form";
import { Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, Badge } from "reactstrap";
import { ProductContext } from "./ProductContext";
import { productCardData } from "./ProductData";

const LojaPositivo = () => {
  
  const { contextData } = useContext(ProductContext);

  const [data, setData] = contextData;

  const [smOption, setSmOption] = useState(false);
  const [formData,setFormData] = useState({
    img: null,
    title: "",
    newPrice: 0,
    novo: false,
    adquirido: false,
  });
  const [view, setView] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(8);

  const toggle = () => {
    setView(!view);
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const { reset, register, handleSubmit, formState: { errors } } = useForm();

  return (
    <React.Fragment>
      <Head title="Product List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Loja Positivo</BlockTitle>
              <BlockDes className="text-soft">
                <p>Encontre aqui produtos e soluções POSITIVO</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            {currentItems.length > 0 ? (
              currentItems.map((item) => {
                return (
                  <Col xxl={3} lg={4} sm={6} key={item.id}>
                    <Card className=" product-card" style={{ width: '90%' }}>
                      <div className="product-thumb">
                        <Link to={`${process.env.PUBLIC_URL}/product-details/${item.id}`}>
                          <img className="card-img-top" src={item.img} alt=""/>
                        </Link>
                        <ul className="product-badges">
                          {item.novo && (
                            <li>
                              <Badge color="danger">Novo</Badge>
                            </li>
                          )}
                          {item.adquirido && (
                            <li>
                              <Badge color="success">Adquirido</Badge>
                            </li>
                          )}
                        </ul>
                        <ul className="product-actions">
                          <li>
                            <a href="#cart" onClick={(ev) => ev.preventDefault()}>
                              <Icon name="cart"></Icon>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="card-inner text-center">
                        <ul className="product-tags">
                        </ul>
                        <h5 className="product-title">
                          <Link to={`${process.env.PUBLIC_URL}/detalhes-produto/${item.id}`}>{item.title}</Link>
                        </h5>
                        <div className="product-price text-primary h5">
                          <h6><small>A partir de </small></h6>R${item.newPrice}
                        </div>
                      </div>

                    </Card>
                  </Col>
                );
              })
            ) : (
              <div className="ms-2">Nenhum produto encontrado</div>
            )}
          </Row>
        </Block>

        {view && <div className="toggle-overlay" onClick={toggle}></div>}
      </Content>
    </React.Fragment>
  );
};
export default LojaPositivo;
