import React, { useState } from "react";
import { Collapse } from "reactstrap";

const Accordion = ({ className, variation, ...props }) => {
  const [isOpen, setIsOpen] = useState("1");

  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };

  return (
    <div className={[`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`]}>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("1")}>
          <h6 className="title">Como funciona o processo de limpeza de nome via ação judicial?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "1" ? true : false}>
          <div className="accordion-inner">
            <p>
              Nós usamos a justiça para ajudar você a limpar seu nome. 
              Entramos com ações judiciais para contestar registros negativos em órgãos de proteção ao crédito, 
              como SPC e Serasa. Isso significa que um juiz pode ordenar a remoção temporária do seu nome desses registros, 
              dando a você a chance de rapidamente recuperar sua possibilidade de acesso ao crédito, ganhando aquele fôlego necessário para reorganizar suas finanças.
            </p>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "2" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("2")}>
          <h6 className="title">É seguro usar este serviço para limpar meu nome?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "2" ? true : false}>
          <div className="accordion-inner">
            <p>
              Sim, nosso serviço é seguro. Trabalhamos com advogados experientes que seguem todas as regras e leis. 
              Garantimos a transparência e ética em cada passo do processo. Todo processo judicial tem seus desafios, porém nossa equipe está comprometida 
              em tornar o processo o mais tranquilo e eficaz possível para você, proporcionando a você uma chance justa de limpar seu nome.
            </p>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "3" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("3")}>
          <h6 className="title">Quanto tempo leva para ver resultados?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "3" ? true : false}>
          <div className="accordion-inner">
            <p>
            Você pode começar a ver resultados em aproximadamente 30 a 60 dias úteis. 
            Esse é um tempo relativamente curto para obter uma decisão judicial que pode resultar na limpeza do seu nome, 
            ajudando você a recuperar seu crédito assim que o processo for concluído.
            </p>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "4" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("4")}>
          <h6 className="title">Minha dívida será eliminada com este processo?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "4" ? true : false}>
          <div className="accordion-inner">
            <p>
            Não, a dívida não é eliminada, nosso foco é remover o registro negativo do seu nome. 
            Isso significa que você pode melhorar sua possibilidade de acesso ao crédito e desta maneira ter acesso a novas oportunidades 
            financeiras enquanto resolve sua dívida de uma forma mais gerenciável.
            </p>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default Accordion;
